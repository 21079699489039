import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import { Plus, X, Trash2 } from 'lucide-react';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Button = styled.button`
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #2563eb;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const DeleteButton = styled(Button)`
  background-color: #ef4444;
  &:hover {
    background-color: #dc2626;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const Th = styled.th`
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 0.25rem;
`;

const StatusTd = styled(Td)`
  width: 100px;
  text-align: center;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  &:hover button {
    opacity: 1;
  }
`;

const Thumbnail = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 0.25rem;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ef4444;
  color: white;
  padding: 0.125rem;
  border-radius: 0 0 0 0.25rem;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
`;

const AddLogoLabel = styled.label`
  cursor: pointer;
`;

const AddLogoButton = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border: 2px dashed #d1d5db;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9ca3af;

  &:hover {
    color: #6b7280;
  }
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const PartnerSectionManagement = () => {
  const [partnerSections, setPartnerSections] = useState([]);
  const [editingHeadlines, setEditingHeadlines] = useState({});
  const backendUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchPartnerSections();
  }, []);

  const fetchPartnerSections = async () => {
    try {
      const response = await api.get('/api/cms/partner-section/get-all');
      console.log('Fetched partner sections:', response.data);
      if (Array.isArray(response.data)) {
        setPartnerSections(response.data);
      } else {
        console.error('Data fetched is not an array:', response.data);
      }
    } catch (error) {
      console.error('Error fetching partner sections:', error);
    }
  };

  const createPartnerSection = async () => {
    try {
      const newPartnerSection = {
        headline: '',
        headlineEN: '',
        active: false,
        logos: []
      };
      console.log('Sending new partner section data:', newPartnerSection);
      const response = await api.post('/api/cms/partner-section', newPartnerSection);
      console.log('Created new partner section:', response.data);
      setPartnerSections(prevSections => [...prevSections, response.data]);
    } catch (error) {
      console.error('Error creating partner section:', error);
    }
  };

  const updatePartnerSection = async (id, updatedData) => {
    try {
      const currentSection = partnerSections.find(section => section.id === id);
      const fullUpdatedSection = {
        ...currentSection,
        ...updatedData
      };
      console.log(`Updating partner section ${id} with data:`, fullUpdatedSection);
      const response = await api.put(`/api/cms/partner-section/${id}`, fullUpdatedSection);
      console.log(`Updated partner section ${id}:`, response.data);
      setPartnerSections(prevSections => 
        prevSections.map(section => section.id === id ? response.data : section)
      );
      return response.data;
    } catch (error) {
      console.error(`Error updating partner section ${id}:`, error);
      return null;
    }
  };

  const deletePartnerSection = async (id) => {
    try {
      // First, delete all logos associated with the partner section
      const section = partnerSections.find(s => s.id === id);
      for (let logo of section.logos) {
        await removeLogo(id, logo.id);
      }

      // Then, delete the partner section
      await api.delete(`/api/cms/partner-section/${id}`);
      console.log(`Deleted partner section ${id}`);
      setPartnerSections(prevSections => prevSections.filter(section => section.id !== id));
    } catch (error) {
      console.error(`Error deleting partner section ${id}:`, error);
    }
  };

  const handleHeadlineChange = (id, lang, newHeadline) => {
    setEditingHeadlines(prev => ({
      ...prev,
      [id]: { ...prev[id], [lang]: newHeadline }
    }));
  };

  const handleHeadlineBlur = async (id, lang) => {
    const newHeadline = editingHeadlines[id]?.[lang];
    if (newHeadline !== undefined && newHeadline !== partnerSections.find(s => s.id === id)[lang === 'de' ? 'headline' : 'headlineEN']) {
      const updatedSection = await updatePartnerSection(id, { [lang === 'de' ? 'headline' : 'headlineEN']: newHeadline });
      if (updatedSection) {
        setEditingHeadlines(prev => {
          const { [id]: _, ...rest } = prev;
          return rest;
        });
      } else {
        // If update failed, revert to the original headline
        setEditingHeadlines(prev => {
          const { [id]: _, ...rest } = prev;
          return rest;
        });
      }
    } else {
      // If no change, just remove from editing state
      setEditingHeadlines(prev => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
    }
  };

  const addLogos = async (partnerSectionId, files) => {
    for (let file of files) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('orderIndex', partnerSections.find(section => section.id === partnerSectionId).logos.length);
        const response = await api.post(`/api/cms/partner-section/${partnerSectionId}/media`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        console.log(`Added logo to partner section ${partnerSectionId}:`, response.data);
        setPartnerSections(prevSections => prevSections.map(section => 
          section.id === partnerSectionId 
            ? { ...section, logos: [...section.logos, response.data] }
            : section
        ));
      } catch (error) {
        console.error(`Error adding logo to partner section ${partnerSectionId}:`, error);
      }
    }
  };
  
    const addLogo = async (partnerSectionId, file) => {
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('orderIndex', partnerSections.find(section => section.id === partnerSectionId).logos.length);
        const response = await api.post(`/api/cms/partner-section/${partnerSectionId}/media`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        console.log(`Added logo to partner section ${partnerSectionId}:`, response.data);
        setPartnerSections(prevSections => prevSections.map(section => 
          section.id === partnerSectionId 
            ? { ...section, logos: [...section.logos, response.data] }
            : section
        ));
      } catch (error) {
        console.error(`Error adding logo to partner section ${partnerSectionId}:`, error);
      }
    };
  
    const removeLogo = async (partnerSectionId, logoId) => {
      try {
        await api.delete(`/api/cms/partner-section/${partnerSectionId}/media/${logoId}`);
        console.log(`Removed logo ${logoId} from partner section ${partnerSectionId}`);
        setPartnerSections(prevSections => prevSections.map(section => 
          section.id === partnerSectionId 
            ? { ...section, logos: section.logos.filter(logo => logo.id !== logoId) }
            : section
        ));
      } catch (error) {
        console.error(`Error removing logo ${logoId} from partner section ${partnerSectionId}:`, error);
      }
    };
  
    const handleActiveChange = async (id, isActive) => {
      const updatedSection = await updatePartnerSection(id, { active: isActive });
      if (!updatedSection) {
        // If update failed, revert the checkbox
        setPartnerSections(prevSections => prevSections.map(section => 
          section.id === id ? { ...section, active: !isActive } : section
        ));
      }
    };
  
    return (
      <Container>
        <Header>
          <Title>Partner Section Management</Title>
          <Button onClick={createPartnerSection}>
            <Plus size={20} />
            Create New Partner Section
          </Button>
        </Header>
        <Table>
          <thead>
            <tr>
              <Th style={{width: '50px'}}>ID</Th>
              <Th>Headline (DE)</Th>
              <Th>Headline (EN)</Th>
              <Th>Logos</Th>
              <Th style={{width: '100px'}}>Status</Th>
              <Th style={{width: '100px'}}>Actions</Th>
            </tr>
          </thead>
          <tbody>
            {partnerSections.map((section) => (
              <tr key={section.id}>
                <Td>{section.id}</Td>
                <Td>
                  <Input
                    value={editingHeadlines[section.id]?.de !== undefined ? editingHeadlines[section.id].de : section.headline}
                    onChange={(e) => handleHeadlineChange(section.id, 'de', e.target.value)}
                    onBlur={() => handleHeadlineBlur(section.id, 'de')}
                    placeholder="Enter headline (DE)"
                  />
                </Td>
                <Td>
                  <Input
                    value={editingHeadlines[section.id]?.en !== undefined ? editingHeadlines[section.id].en : section.headlineEN}
                    onChange={(e) => handleHeadlineChange(section.id, 'en', e.target.value)}
                    onBlur={() => handleHeadlineBlur(section.id, 'en')}
                    placeholder="Enter headline (EN)"
                  />
                </Td>
                <Td>
                  <LogoContainer>
                    {section.logos.map((logo) => (
                      <ThumbnailWrapper key={logo.id}>
                        <Thumbnail 
                          src={`${backendUrl}${logo.mediaUrl}`} 
                          alt={`Logo ${logo.id}`} 
                        />
                        <RemoveButton onClick={() => removeLogo(section.id, logo.id)}>
                          <X size={8} />
                        </RemoveButton>
                      </ThumbnailWrapper>
                    ))}
                    <AddLogoLabel>
                      <input 
                        type="file" 
                        multiple
                        onChange={(e) => addLogos(section.id, e.target.files)}
                        style={{ display: 'none' }}
                      />
                      <AddLogoButton>
                        <Plus size={16} />
                      </AddLogoButton>
                    </AddLogoLabel>
                  </LogoContainer>
                </Td>
                <StatusTd>
                  <label>
                    <Checkbox 
                      type="checkbox" 
                      checked={section.active}
                      onChange={(e) => handleActiveChange(section.id, e.target.checked)}
                    />
                  </label>
                </StatusTd>
                <Td>
                  <DeleteButton onClick={() => deletePartnerSection(section.id)}>
                    <Trash2 size={16} />
                  </DeleteButton>
                </Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  };
  
  export default PartnerSectionManagement;
  