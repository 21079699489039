import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8080';
const REFRESH_TOKEN_ENDPOINT = '/api/refresh-token';


const api = axios.create({
  baseURL: apiUrl,
  timeout: 600000,
});


const isTokenExpired = (token) => {
  if (!token) return true;
  const expiry = JSON.parse(atob(token.split('.')[1])).exp;
  const now = Math.floor(new Date().getTime() / 1000);
  return now >= expiry;
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const refreshToken = async () => {
  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      failedQueue.push({ resolve, reject });
    });
  }

  isRefreshing = true;
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    isRefreshing = false;
    return Promise.reject('No refresh token available');
  }

  try {
    const response = await api.post(REFRESH_TOKEN_ENDPOINT, { refreshToken });
    const { token, newRefreshToken } = response.data;
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', newRefreshToken);
    isRefreshing = false;
    processQueue(null, token);
    return token;
  } catch (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    isRefreshing = false;
    processQueue(error, null);
    throw error;
  }
};

// Request Interceptor
api.interceptors.request.use(async (config) => {
  // Skip token check for authenticate and refresh token requests
  if (config.url === REFRESH_TOKEN_ENDPOINT || config.url === '/api/authenticate') {
    return config;
  }

  let token = localStorage.getItem('token');
  let refreshToken = localStorage.getItem('refreshToken');

  if (isTokenExpired(token)) {
    if (refreshToken && !isTokenExpired(refreshToken)) {
      try {
        token = await refreshToken();
      } catch (error) {
        console.error('Token refresh error:', error);
        // Clear tokens if refresh failed
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        token = null;
        refreshToken = null;
      }
    } else {
      // Both tokens are missing or expired, attempt to login
      try {
        // You need to implement a way to securely store and retrieve username and password
        // This is just a placeholder - DO NOT store passwords in localStorage in a real app
        const username = localStorage.getItem('username');
        const password = localStorage.getItem('password');
        if (username && password) {
          await login(username, password, config);
          // login function will update the config with the new token
          return config;
        } else {
          throw new Error('No credentials available for automatic login');
        }
      } catch (loginError) {
        console.error('Automatic login failed:', loginError);
        throw new axios.Cancel('Authentication required. Please login.');
      }
    }
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  console.error('Request interceptor error:', error);
  return Promise.reject(error);
});

// Response Interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.error('Response error:', error);

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Refresh token error:', refreshError);
        throw new axios.Cancel('Session expired. Please login again.');
      }
    }
    return Promise.reject(error);
  }
);

export const login = async (username, password, config = null) => {
  try {
    const response = await api.post('/api/authenticate', { username, password });

    if (response.data && response.data.token && response.data.refreshToken) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      
      // If this login was called from the interceptor, update the original request's header
      if (config) {
        config.headers.Authorization = `Bearer ${response.data.token}`;
      }
      
      return response.data;
    } else {
      console.error("Login response missing token or refreshToken:", response.data);
      throw new Error("Invalid response from server");
    }
  } catch (error) {
    console.error('Login error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
};

export default api;
