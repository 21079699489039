import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ChevronRight } from 'lucide-react';
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  background: transparent;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-grow: 1;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Headline = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  color: #040A19;
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
`;

const Title = styled.h2`
  color: #49ABDB;
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
  flex-basis: 25%;

  @media (max-width: 768px) {
    flex-basis: auto;
    margin-bottom: 10px;
    padding: 10px;
  }
`;

const ImageContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  margin: 0 20px;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 768px) {
    height: 300px;
    margin: 10px 0;
  }
`;

const PartnerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const NavigationButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #1e90ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  transition: color 0.3s ease;
  flex-basis: 25%;

  &:hover {
    color: #1e90ff;
  }

  @media (max-width: 768px) {
    flex-basis: auto;
    margin-top: 10px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

const BusinessPartnersDisplay = () => {
  const [partners, setPartners] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        if (!apiUrl) {
          throw new Error('API URL is not defined');
        }
        const response = await axios.get(`${apiUrl}/api/cms/business-partner/public/get-all-active`);
        if (Array.isArray(response.data) && response.data.length > 0) {
          setPartners(response.data);
        } else {
          setError('No business partners found');
        }
      } catch (error) {
        console.error('Error fetching business partners:', error);
        setError('Failed to load business partners');
      }
    };

    fetchPartners();
  }, []);

  const handleNextImage = () => {
    if (partners.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % partners.length);
    }
  };

  if (error) {
    return null;
  }

  if (partners.length === 0) {
    return null;
  }

  const currentPartner = partners[currentIndex];

  return (
    <Container>
      <Headline>
        <FormattedMessage
          id="businessPartners.headline"
          defaultMessage="OUR BUSINESS PARTNERS"
        />
      </Headline>
      <ContentWrapper>
        <Title>
          <FormattedMessage
            id="businessPartners.title"
            defaultMessage="Hall of Hearts"
          />
        </Title>
        <ImageContainer>
          {currentPartner && currentPartner.mediaUrl && (
            <PartnerImage
              src={`${process.env.REACT_APP_API_URL || ''}${currentPartner.mediaUrl}`}
              alt={
                <FormattedMessage
                  id="businessPartners.imageAlt"
                  defaultMessage="Business Partner Logo"
                />
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = 'path/to/fallback/image.jpg'; // Replace with a path to a fallback image
              }}
            />
          )}
        </ImageContainer>
        <NavigationButton onClick={handleNextImage} disabled={partners.length <= 1}>
          <ChevronRight size={48} />
        </NavigationButton>
      </ContentWrapper>
    </Container>
  );
};

export default BusinessPartnersDisplay;