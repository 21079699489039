import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../utils/authUtils';

import UserStatistics from './UserStatistics';

const Container = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const Title = styled.h1`
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`;

const UserManagement = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      await api.get('/api/protected');
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error checking auth status:', error);
      setIsLoggedIn(false);
      navigate('/admin/login');
    }
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Container>
        <Title>User Management</Title>
        
        <UserStatistics />

    </Container>

  );
}

export default UserManagement;