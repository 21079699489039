import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { IntlProvider, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import './styles/main.css';

// Import pages and components
import Home from './pages/Home';
import Contact from './pages/Contact';
import AdminLogin from './components/admin/AdminLogin';
import AdminDashboard from './components/admin/AdminDashboard';
import ProtectedAdminRoute from './components/admin/ProtectedAdminRoute';
import ContentManagement from './components/admin/ContentManagement/ContentManagement';
import UserManagement from './components/admin/UserManagement/UserManagement';
import ResetPassword  from './components/admin/ResetPassword';

import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Modal from './components/Modal';
import RegistrationForm from './components/RegistrationForm';
import ImpressumDisplay from './components/ImpressumDisplay';
import ScrollToTop from './components/ScrollToTop';

import CookieConsent from './components/CookieConsent';
import MatomoTracker from './components/MatomoTracker';

// Import language files
import de from './locales/de.json';
import en from './locales/en.json';

// Import the ConferenceProvider
import { ConferenceProvider } from './contexts/ConferenceContext';
import { LanguageProvider, useLanguage } from './contexts/LanguageContext';

const messages = {
  de: de,
  en: en,
};

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(to bottom, #CAD4DD, #060d25);
`;

const ContentWrapper = styled.main`
  flex: 1;
`;

function AppContent() {
  const { language } = useLanguage();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  console.log('selectedEvent: ', selectedEvent)
  const openModal = (eventInfo) => {
    setSelectedEvent(eventInfo || {});
    setModalOpen(true);
    setRegistrationSuccess(false);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedEvent(null);
    setRegistrationSuccess(false);
  };

  const handleRegistrationSuccess = () => {
    setRegistrationSuccess(true);
    setTimeout(() => {
      closeModal();
    }, 3000);
  };

  const getContent = (defaultContent, enContent) => {
    console.log('selectedEvent 2: ', selectedEvent)

    return language === 'en' && enContent ? enContent : (defaultContent || '');
  };

  return (
    <IntlProvider messages={messages[language]} locale={language}>
      <AppWrapper>
        <Modal
          isOpen={modalOpen}
          onClose={closeModal}
          title={
            registrationSuccess 
              ? <FormattedMessage id="registration.success" defaultMessage="Registration Successful" />
              : <>{getContent(selectedEvent?.mainHeadline, selectedEvent?.mainHeadlineEN) || ''}</>
            }
        >
          <RegistrationForm
            onSubmitSuccess={handleRegistrationSuccess} 
            eventInfo={selectedEvent || {}}
            registrationSuccess={registrationSuccess}
          />
        </Modal>
        <Header />
        <ScrollToTop />
        <ContentWrapper>
          <Routes>
            <Route path="/" element={<Home openRegistrationModal={openModal} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/admin/dashboard" element={<ProtectedAdminRoute><AdminDashboard /></ProtectedAdminRoute>} />
            <Route path="/admin/user-management" element={<ProtectedAdminRoute><UserManagement /></ProtectedAdminRoute>} />
            <Route path="/admin/content-management" element={<ProtectedAdminRoute><ContentManagement /></ProtectedAdminRoute>} />
            <Route path="/uploads/*" element={null} />
            <Route path="/impressum" element={<ImpressumDisplay />} />
            <Route path="/reset-password" element={<ResetPassword />} />


          </Routes>
        </ContentWrapper>
        <Footer />
      </AppWrapper>
    </IntlProvider>
  );
}

function App() {
  return (
    <LanguageProvider>
      <ConferenceProvider>
        <Router>
          <MatomoTracker />
          <CookieConsent />
          <AppContent />
        </Router>
      </ConferenceProvider>
    </LanguageProvider>
  );
}

export default App;