import React from 'react';
import styled from 'styled-components';
import flower from '../assets/images/flower_1.png';


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 0px;
  width: 90%;
  max-width: 500px;
  height: 90vh;
  max-height: 90vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const FlowerImage = styled.div`
  position: absolute;
  top: 50%;
  left: -150px;
  transform: translateY(-50%);
  width: 641px;
  height: 742px;
  background-image: url(${flower});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  z-index: 1;
  pointer-events: none;
  @media (max-width: 768px) {
    opacity: 0.1;
  }
`;

const ContentWrapper = styled.div`
  padding: 20px;
  position: relative;
  z-index: 2;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  margin: 0;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const Modal = ({ isOpen = false, onClose, title = '', children = null }) => {
  if (!isOpen) return null;

  const handleClose = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <FlowerImage />
        <ContentWrapper>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <CloseButton onClick={handleClose}>&times;</CloseButton>
          </ModalHeader>
          {children}
        </ContentWrapper>
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;