import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import { Edit, Play } from 'lucide-react';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const Th = styled.th`
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
`;

const Button = styled.button`
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #2563eb;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const EditButton = styled(Button)`
  background-color: #10b981;
  &:hover {
    background-color: #059669;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #f3f4f6;
  border-radius: 0.5rem;
  margin-top: 1rem;
`;

const InputField = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
  resize: vertical;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 5.76rem;
  height: 3.24rem;
`;

const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  border-radius: 0.25rem;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.25rem;
`;

const LanguageSection = styled.div`
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f3f4f6;
  border-radius: 0.5rem;
`;

const LanguageTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const SubtextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const VideoWithTextManagement = () => {
  const [videoWithTextItems, setVideoWithTextItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const backendUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchVideoWithTextItems();
  }, []);

  const fetchVideoWithTextItems = async () => {
    try {
      const response = await api.get('/api/cms/video-with-text/get-all');
      setVideoWithTextItems(response.data);
    } catch (error) {
      console.error('Error fetching VideoWithText items:', error);
    }
  };

  const updateVideoWithTextItem = async (updatedItem) => {
    try {
      const formData = new FormData();
      formData.append('headline', updatedItem.headline);
      formData.append('description', updatedItem.description);
      formData.append('headlineEN', updatedItem.headlineEN);
      formData.append('descriptionEN', updatedItem.descriptionEN);
      updatedItem.subtexts.forEach((subtext) => {
        formData.append('subtexts', subtext);
      });
      updatedItem.subtextsEN.forEach((subtext) => {
        formData.append('subtextsEN', subtext);
      });
      formData.append('active', updatedItem.active);
      if (updatedItem.file) {
        formData.append('file', updatedItem.file);
      }

      const response = await api.put(`/api/cms/video-with-text/${updatedItem.id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setVideoWithTextItems(videoWithTextItems.map(item => 
        item.id === updatedItem.id ? {...item, ...response.data} : item
      ));
      setEditingItem(null);
    } catch (error) {
      console.error(`Error updating VideoWithText item ${updatedItem.id}:`, error);
    }
  };

  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditingItem(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };


  const handleFileChange = (e) => {
    setEditingItem(prev => ({
      ...prev,
      file: e.target.files[0]
    }));
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    updateVideoWithTextItem(editingItem);
  };

  const renderThumbnail = (item) => {
    const thumbnailUrl = `${backendUrl}${item.mediaUrl}`;
    return (
      <ThumbnailWrapper>
        <Thumbnail src={thumbnailUrl} />
        {item.mediaType === 'VIDEO' && (
          <VideoOverlay>
            <Play size={16} color="white" />
          </VideoOverlay>
        )}
      </ThumbnailWrapper>
    );
  };

  const handleSubtextChange = (index, value, lang) => {
    const field = lang === 'en' ? 'subtextsEN' : 'subtexts';
    setEditingItem(prev => ({
      ...prev,
      [field]: prev[field].map((subtext, i) => i === index ? value : subtext)
    }));
  };

  return (
    <Container>
      <Header>
        <Title>Video With Text Management</Title>
      </Header>
      <Table>
        <thead>
          <tr>
            <Th style={{width: '50px'}}>ID</Th>
            <Th>Media</Th>
            <Th>German Content</Th>
            <Th>English Content</Th>
            <Th style={{width: '100px'}}>Status</Th>
            <Th style={{width: '100px'}}>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {videoWithTextItems.map((item) => (
            <tr key={item.id}>
              <Td>{item.id}</Td>
              <Td>{renderThumbnail(item)}</Td>
              <Td>
                <strong>Headline:</strong> {item.headline}<br/>
                <strong>Description:</strong> {item.description}<br/>
                <strong>Subtexts:</strong> {item.subtexts.join(', ')}
              </Td>
              <Td>
                <strong>Headline:</strong> {item.headlineEN}<br/>
                <strong>Description:</strong> {item.descriptionEN}<br/>
                <strong>Subtexts:</strong> {item.subtextsEN.join(', ')}
              </Td>
              <Td>
                <Checkbox 
                  type="checkbox" 
                  checked={item.active}
                  onChange={() => {}}
                  disabled
                />
              </Td>
              <Td>
                <EditButton onClick={() => setEditingItem(item)}>
                  <Edit size={16} />
                  Edit
                </EditButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
      {editingItem && (
        <Form onSubmit={handleEditSubmit}>
          <LanguageSection>
            <LanguageTitle>German Content</LanguageTitle>
            <InputField
              name="headline"
              value={editingItem.headline}
              onChange={handleEditChange}
              placeholder="Headline (DE)"
            />
            <TextArea
              name="description"
              value={editingItem.description}
              onChange={handleEditChange}
              placeholder="Description (DE)"
              rows={4}
            />
            <SubtextContainer>
              {editingItem.subtexts.map((subtext, index) => (
                <InputField
                  key={`de-${index}`}
                  value={subtext}
                  onChange={(e) => handleSubtextChange(index, e.target.value, 'de')}
                  placeholder={`Subtext ${index + 1} (DE)`}
                />
              ))}
            </SubtextContainer>
          </LanguageSection>

          <LanguageSection>
            <LanguageTitle>English Content</LanguageTitle>
            <InputField
              name="headlineEN"
              value={editingItem.headlineEN}
              onChange={handleEditChange}
              placeholder="Headline (EN)"
            />
            <TextArea
              name="descriptionEN"
              value={editingItem.descriptionEN}
              onChange={handleEditChange}
              placeholder="Description (EN)"
              rows={4}
            />
            <SubtextContainer>
              {editingItem.subtextsEN.map((subtext, index) => (
                <InputField
                  key={`en-${index}`}
                  value={subtext}
                  onChange={(e) => handleSubtextChange(index, e.target.value, 'en')}
                  placeholder={`Subtext ${index + 1} (EN)`}
                />
              ))}
            </SubtextContainer>
          </LanguageSection>

          <InputField
            type="file"
            onChange={handleFileChange}
            accept="image/*,video/*"
          />
          <label>
            <Checkbox
              type="checkbox"
              name="active"
              checked={editingItem.active}
              onChange={handleEditChange}
            />
            Active
          </label>
          <ButtonGroup>
            <Button type="button" onClick={() => setEditingItem(null)}>Cancel</Button>
            <Button type="submit" primary>Save</Button>
          </ButtonGroup>
        </Form>
      )}
    </Container>
  );
};

export default VideoWithTextManagement;
