import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLanguage } from '../contexts/LanguageContext';

const SectionWrapper = styled.section`
  background-color: #90C4D9;
  padding: 100px 20px 40px;

  @media (max-width: 768px) {
    padding: 50px 0px 0px;
  }
`;

const SectionTitle = styled.h2`
  color: white;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SectionSubTitle = styled.h2`
  color: white;
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 70px;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
`;

const EventWrapper = styled.div`
  background: transparent;
  padding: 20px;
  margin-bottom: 30px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1700px;
  width: 100%;
  margin: 0 auto;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  padding-right: 20px;

  @media (max-width: 1024px) {
    padding-right: 0;
    text-align: center;
    max-width: 600px;
    width: 100%;
    padding-right: 0px;
  }
`;

const MiddleColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    max-width: 600px;
    width: 100%;
  }
`;

const RightColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20px;

  @media (max-width: 1024px) {
    padding-left: 0;
    max-width: 600px;
    width: 100%;
    padding-bottom: 0px;
  }
`;

const EventTitle = styled.h3`
  font-size: 3rem;
  margin-top: 40px;
  margin-bottom: 0px;
  display: inline-block;
  padding-bottom: 6px;
  background-image: linear-gradient(
    to right,
    #4e4094, #14cbde 
  );
  background-size: 100% 2px;
  background-position: 0 bottom;
  background-repeat: no-repeat;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-image: linear-gradient(
      to right,
      transparent 50%,
      #90C4D9 50%
    );
    background-size: 8px 2px;
    background-repeat: repeat-x;
  }

  @media (max-width: 1024px) {
    font-size: 1.5rem;
    margin-top: 0px;
    text-align: center;
  }
`;

const SecondaryHeadline = styled.h4`
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    font-size: 1.4rem;
    margin-top: 20px;
    margin-bottom: 10px;

  }
`;

const BulletList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 1.4rem;

  @media (max-width: 1024px) {
    display: inline-block;
    text-align: left;
    font-size: 1rem;
    margin-bottom: 10px;

  }
`;

const TextContent = styled.p`
  font-size: 1.4rem;
  line-height:1.5;
  margin-bottom: 20px;
  padding: 0 0 0 0;
  @media (max-width: 1024px) {
    padding: 0;
    font-size: 1rem;
  }
`;

const EventImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
`;

const EventButton = styled.button`
  background-color: #47ABDB;
  text-decoration: none;
  height: 4.0rem;
  font-weight: bold;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 1024px) {
  }
`;

const Button = styled.button`
  background-color: #47ABDB;
  text-decoration: none;
  height: 4.0rem;
  font-weight: bold;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.8rem;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 1024px) {
  }
`;

const EventInfo = styled.div`
  font-size: 1.4rem;
  margin-bottom: 40px;
  text-align: left;
  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

const EventButtonTitle = styled.p`
  margin-bottom: 0.1rem;
`;

const EventDetails = styled.p`
  margin: 0;
`;

const SubText = styled.p`
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.5;
  margin-top: 40px;
  padding: 0 40px 0 0;
  @media (max-width: 1024px) {
    font-size: 1rem;
    padding: 0;
    text-align: center;
  }
`;

const OtherHeartsEvents = ({ event = {}, openRegistrationModal }) => {
  const { language } = useLanguage();
  const intl = useIntl();

  const formatDate = (dateString) => {
    if (!dateString) {
      return '';
    }
    
    const date = new Date(dateString);
    
    if (isNaN(date.getTime())) {
      return '';
    }
    
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  };

  const handleProgramClick = (e) => {
    e.preventDefault();
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const fullUrl = `${apiUrl}${event.programUrl || ''}`;
    if (event.programUrl) {
      window.open(fullUrl, '_blank', 'noopener,noreferrer');
    }
  };

  const handleRegisterClick = () => {
    if (typeof openRegistrationModal === 'function') {
      openRegistrationModal(event);
    }
  };

  const getContent = (defaultContent, enContent) => {
    return language === 'en' && enContent ? enContent : (defaultContent || '');
  };

  return (
    <SectionWrapper>
      <SectionTitle>{getContent(event.bigHeadline, event.bigHeadlineEN) || ''}</SectionTitle>
      <SectionSubTitle>{getContent(event.subHeadline, event.subHeadlineEN) || ''}</SectionSubTitle>
      
      <EventWrapper>
        <ContentWrapper>
          <LeftColumn>
            <EventTitle>{getContent(event.mainHeadline, event.mainHeadlineEN) || ''}</EventTitle>
            {event.bulletPointsHeadline && (
              <>
                <SecondaryHeadline>{getContent(event.bulletPointsHeadline.headline, event.bulletPointsHeadline.headlineEN) || ''}</SecondaryHeadline>
                <BulletList>
                  {(getContent(event.bulletPointsHeadline.bulletPoints, event.bulletPointsHeadline.bulletPointsEN) || []).map((point, index) => (
                    <li key={index}>{point}</li>
                  ))}
                </BulletList>
              </>
            )}
            {event.textHeadline && (
              <>
                <SecondaryHeadline>{getContent(event.textHeadline.headline, event.textHeadline.headlineEN) || ''}</SecondaryHeadline>
                <TextContent>{getContent(event.textHeadline.textContent, event.textHeadline.textContentEN) || ''}</TextContent>
              </>
            )}
            {(event.subText || event.subTextEN) && (
              <SubText>{getContent(event.subText, event.subTextEN)}</SubText>
            )}
          </LeftColumn>
          <MiddleColumn>
            {event.mainMediaUrl && (
              <EventImage 
                src={`${process.env.REACT_APP_API_URL || ''}${event.mainMediaUrl}`} 
                alt={getContent(event.mainHeadline, event.mainHeadlineEN) || ''}
              />
            )}
          </MiddleColumn>
          <RightColumn>
            <EventButton onClick={handleRegisterClick}>
              <FormattedMessage id="event.register" defaultMessage="REGISTER NOW" />
            </EventButton>
            <EventInfo>
              <EventButtonTitle>
              <>{getContent(event?.mainHeadline, event?.mainHeadlineEN) || ''}</>
              </EventButtonTitle>
              <EventDetails>
                {formatDate(event.dateTime)}
                {event.location && ` - ${event.location}`}
              </EventDetails>
            </EventInfo>
            {event.programUrl && (
              <>
                <Button onClick={handleProgramClick}>
                  <FormattedMessage id="event.program" defaultMessage="PROGRAM" />
                </Button>
                <EventInfo>
                  <EventButtonTitle>{getContent(event.programText, event.programTextEN) || ''}</EventButtonTitle>
                </EventInfo>
              </>
            )}
          </RightColumn>
        </ContentWrapper>
      </EventWrapper>
    </SectionWrapper>
  );
};

export default OtherHeartsEvents;