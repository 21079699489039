import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import { Plus, X, Play, Edit } from 'lucide-react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Button = styled.button`
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #2563eb;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const Th = styled.th`
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
`;

const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 0.25rem;
  height: 3.75rem;
`;

const StatusTd = styled(Td)`
  width: 100px;
  text-align: center;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 5.76rem;
  height: 3.24rem;
  &:hover button {
    opacity: 1;
  }
`;

const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  border-radius: 0.25rem;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.25rem;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ef4444;
  color: white;
  padding: 0.125rem;
  border-radius: 0 0 0 0.25rem;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
  z-index: 10;
`;

const AddMediaLabel = styled.label`
  cursor: pointer;
`;

const AddMediaButton = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border: 2px dashed #d1d5db;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9ca3af;

  &:hover {
    color: #6b7280;
  }
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
`;

const EditButton = styled(Button)`
  background-color: #10b981;
  &:hover {
    background-color: #059669;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #f3f4f6;
  border-radius: 0.5rem;
  margin-top: 1rem;
`;

const InputField = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
  resize: vertical;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const LanguageColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const LanguageHeader = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const VideoThumbnail = ({ src }) => {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const handleLoadedMetadata = () => {
        video.currentTime = 1; // Seek to 1 second
      };

      const handleSeeked = () => {
        setIsLoaded(true);
      };

      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.addEventListener('seeked', handleSeeked);

      return () => {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        video.removeEventListener('seeked', handleSeeked);
      };
    }
  }, [src]);

  return (
    <Video 
      ref={videoRef}
      src={src}
      poster={src}
      style={{
        opacity: isLoaded ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out'
      }}
    />
  );
};

const HeroSectionManagement = () => {
  const [heroSections, setHeroSections] = useState([]);
  const [editingSection, setEditingSection] = useState(null);
  const backendUrl = process.env.REACT_APP_API_URL || '';
  const [isUploading, setIsUploading] = useState(false);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [stompClient, setStompClient] = useState(null);
  const [uploadProgress, setUploadProgress] = useState({});

  useEffect(() => {
    fetchHeroSections();
  }, []);

  useEffect(() => {
    const hasUnprocessedUploads = uploadQueue.some(item => !item.processing);
    if (hasUnprocessedUploads && !isUploading) {
      processNextUpload();
    }
  }, [uploadQueue, isUploading]);

  useEffect(() => {
    const socket = new SockJS(`${backendUrl}/ws`);
    const client = Stomp.over(socket);
    client.debug = () => {};
  
    client.connect({}, () => {
      client.subscribe('/topic/upload-progress', (message) => {
        const progress = JSON.parse(message.body);
        setUploadProgress(prev => ({
          ...prev,
          [progress.fileName]: progress.progress
        }));
      });
    });
    setStompClient(client);
  
    return () => {
      if (client) {
        client.disconnect();
      }
    };
  }, []);


  const fetchHeroSections = async () => {
    try {
      const response = await api.get('/api/cms/hero-section');
      console.log('Fetched hero sections:', response.data);

      if (Array.isArray(response.data)) {
        setHeroSections(response.data);
      } else {
        console.error('Data fetched is not an array:', response.data);
        setHeroSections([]); // Set to empty array if data is invalid
      }
    } catch (error) {
      console.error('Error fetching hero sections:', error);
      setHeroSections([]); // Set to empty array on error
    }
  };

  const updateHeroSection = async (updatedSection) => {
    try {
      const response = await api.put(`/api/cms/hero-section/${updatedSection.id}`, updatedSection);
      console.log(`Updated hero section ${updatedSection.id}:`, response.data);
      setHeroSections(heroSections.map(section => 
        section.id === updatedSection.id ? {...section, ...response.data} : section
      ));
      setEditingSection(null);
    } catch (error) {
      console.error(`Error updating hero section ${updatedSection.id}:`, error);
    }
  };

  const updateHeroSectionStatus = async (id, active) => {
    try {
      const heroSection = heroSections.find(section => section.id === id);
      if (!heroSection) {
        console.error(`Hero section with id ${id} not found`);
        return;
      }
      const updatedHeroSection = { 
        ...heroSection, 
        active: active
      };
      console.log('Sending updated hero section data:', updatedHeroSection);
      const response = await api.put(`/api/cms/hero-section/${id}`, updatedHeroSection);
      console.log(`Updated hero section ${id} status to:`, active);
      console.log('Response from server:', response.data);
      setHeroSections(heroSections.map(section => 
        section.id === id ? {...section, active: active} : section
      ));
    } catch (error) {
      console.error(`Error updating hero section ${id} status:`, error);
    }
  };

  const getMediaType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const videoExtensions = ['mp4', 'avi', 'mov'];
    
    if (imageExtensions.includes(extension)) {
      return 'IMAGE';
    } else if (videoExtensions.includes(extension)) {
      return 'VIDEO';
    } else {
      console.warn(`Unknown file type: ${extension}. Defaulting to IMAGE.`);
      return 'IMAGE';
    }
  };

  const addSlideShowItemToDatabase = async (heroSectionId, fileName, mediaType, orderIndex) => {
    try {
      const response = await api.post(`/api/cms/hero-section/${heroSectionId}/media/add`, {
        fileName,
        mediaType, // Send mediaType as is, no need for toUpperCase
        orderIndex
      });
      console.log(`Added slideshow item to hero section ${heroSectionId}:`, response.data);
      setHeroSections(heroSections.map(section => 
        section.id === heroSectionId 
          ? { ...section, slideShowItems: [...(section.slideShowItems || []), response.data] }
          : section
      ));
    } catch (error) {
      console.error(`Error adding slideshow item to database for hero section ${heroSectionId}:`, error);
    }
  };
  
  const uploadSlideShowItem = async (heroSectionId, file) => {
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      
      // Initialize progress to 0%
      setUploadProgress(prev => ({
        ...prev,
        [file.name]: 0
      }));
  
      const mediaType = getMediaType(file.name);
      console.log(`Uploading file: ${file.name}, Media Type: ${mediaType}`);
      
      const response = await api.post(`/api/cms/hero-section/${heroSectionId}/media/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(prev => ({
            ...prev,
            [file.name]: percentCompleted
          }));
        }
      });
  
      console.log(`Uploaded file for hero section ${heroSectionId}:`, response.data);
      return { ...response.data, mediaType };
    } catch (error) {
      console.error(`Error uploading file for hero section ${heroSectionId}:`, error);
      throw error;
    } finally {
      setIsUploading(false);
      // Remove progress entry when upload is complete
      setUploadProgress(prev => {
        const newProgress = {...prev};
        delete newProgress[file.name];
        return newProgress;
      });
    }
  };
  

  

  const addSlideShowItem = (heroSectionId, file) => {
    console.log("adding to queue")
    setUploadQueue(prevQueue => [...prevQueue, { heroSectionId, file, processing: false }]);
  };

  const processNextUpload = async () => {
    if (uploadQueue.length === 0) return;
  
    const nextUpload = uploadQueue.find(item => !item.processing);
    if (!nextUpload) return;
  
    const { heroSectionId, file } = nextUpload;
    
    setUploadQueue(prevQueue => 
      prevQueue.map(item => 
        item === nextUpload ? { ...item, processing: true } : item
      )
    );
  
    try {
      setIsUploading(true);
      const uploadResult = await uploadSlideShowItem(heroSectionId, file);
      const heroSection = heroSections.find(section => section.id === heroSectionId);
      const orderIndex = heroSection.slideShowItems?.length || 0;
      
      await addSlideShowItemToDatabase(heroSectionId, uploadResult.fileName, uploadResult.mediaType, orderIndex);
    } catch (error) {
      console.error('Error processing upload:', error);
    } finally {
      setIsUploading(false);
      setUploadQueue(prevQueue => prevQueue.filter(item => item !== nextUpload));
    }
  };

  const removeSlideShowItem = async (heroSectionId, itemId) => {
    try {
      if (!heroSectionId) {
        console.error('Hero section ID is undefined');
        return;
      }
      await api.delete(`/api/cms/hero-section/${heroSectionId}/media/${itemId}`);
      console.log(`Removed slideshow item ${itemId} from hero section ${heroSectionId}`);
      setHeroSections(heroSections.map(section => 
        section.id === heroSectionId 
          ? { ...section, slideShowItems: (section.slideShowItems || []).filter(item => item.id !== itemId) }
          : section
      ));
    } catch (error) {
      console.error(`Error removing slideshow item ${itemId} from hero section ${heroSectionId}:`, error);
    }
  };

  const renderThumbnail = (item, heroSectionId) => {
    if (!item || !item.mediaUrl) {
      console.error('Invalid item or missing mediaUrl', item);
      return null;
    }
    const thumbnailUrl = `${backendUrl}${item.mediaUrl}`;
    return (
      <ThumbnailWrapper key={item.id}>
        {item.mediaType === 'VIDEO' ? (
          <VideoThumbnail src={thumbnailUrl} />
        ) : (
          <Thumbnail src={thumbnailUrl} />
        )}
        {item.mediaType === 'VIDEO' && (
          <VideoOverlay>
            <Play size={16} color="white" />
          </VideoOverlay>
        )}
        <RemoveButton onClick={() => removeSlideShowItem(heroSectionId, item.id)}>
          <X size={8} />
        </RemoveButton>
      </ThumbnailWrapper>
    );
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingSection(prev => ({ ...prev, [name]: value }));
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (editingSection) {
      updateHeroSection(editingSection);
    }
  };
  
  return (
    <Container>
      <Header>
        <Title>Hero Section Management</Title>
      </Header>
      <Table>
        <thead>
          <tr>
            <Th style={{width: '50px'}}>ID</Th>
            <Th>Media</Th>
            <Th>German Content</Th>
            <Th>English Content</Th>
            <Th style={{width: '100px'}}>Status</Th>
            <Th style={{width: '100px'}}>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {heroSections.map((section) => (
            <tr key={section.id}>
              <Td>{section.id}</Td>
              <Td>
                <MediaContainer>
                  {(section.slideShowItems || []).map(item => renderThumbnail(item, section.id))}
                  <AddMediaLabel>
                    <input 
                      type="file"
                      accept="image/*, video/*"
                      onChange={(e) => addSlideShowItem(section.id, e.target.files[0])}
                      style={{ display: 'none' }}
                    />
                    <AddMediaButton>
                      <Plus size={16} />
                    </AddMediaButton>
                  </AddMediaLabel>
                </MediaContainer>
              </Td>
              <Td>
                <div>
                  <strong>Big Headline:</strong> {section.bigHeadline || ''}<br/>
                  <strong>Sub Headline:</strong> {section.subHeadline || ''}<br/>
                  <strong>Science Cares:</strong> {section.scienceCares || ''}
                </div>
              </Td>
              <Td>
                <div>
                  <strong>Big Headline:</strong> {section.bigHeadlineEN || ''}<br/>
                  <strong>Sub Headline:</strong> {section.subHeadlineEN || ''}<br/>
                  <strong>Science Cares:</strong> {section.scienceCaresEN || ''}
                </div>
              </Td>
              <StatusTd>
                <label>
                  <Checkbox 
                    type="checkbox" 
                    checked={section.active || false}
                    onChange={(e) => updateHeroSectionStatus(section.id, e.target.checked)}
                    disabled={isUploading}
                  />
                </label>
              </StatusTd>
              <Td>
                <EditButton onClick={() => setEditingSection(section)}>
                  <Edit size={16} />
                  Edit
                </EditButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
      {editingSection && (
        <Form onSubmit={handleEditSubmit}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <LanguageColumn>
              <LanguageHeader>German Content</LanguageHeader>
              <InputField
                name="bigHeadline"
                value={editingSection.bigHeadline || ''}
                onChange={handleEditChange}
                placeholder="Big Headline (DE)"
              />
              <InputField
                name="subHeadline"
                value={editingSection.subHeadline || ''}
                onChange={handleEditChange}
                placeholder="Sub Headline (DE)"
              />
              <TextArea
                name="scienceCares"
                value={editingSection.scienceCares || ''}
                onChange={handleEditChange}
                placeholder="Science Cares (DE)"
                rows={4}
              />
            </LanguageColumn>
            <LanguageColumn>
              <LanguageHeader>English Content</LanguageHeader>
              <InputField
                name="bigHeadlineEN"
                value={editingSection.bigHeadlineEN || ''}
                onChange={handleEditChange}
                placeholder="Big Headline (EN)"
              />
              <InputField
                name="subHeadlineEN"
                value={editingSection.subHeadlineEN || ''}
                onChange={handleEditChange}
                placeholder="Sub Headline (EN)"
              />
              <TextArea
                name="scienceCaresEN"
                value={editingSection.scienceCaresEN || ''}
                onChange={handleEditChange}
                placeholder="Science Cares (EN)"
                rows={4}
              />
            </LanguageColumn>
          </div>
          <ButtonGroup>
            <Button type="button" onClick={() => setEditingSection(null)}>Cancel</Button>
            <Button type="submit" primary>Save</Button>
          </ButtonGroup>
        </Form>
      )}
      {Object.entries(uploadProgress).map(([fileName, progress]) => (
        <div key={fileName}>
          {fileName}: {progress}%
          <progress value={progress} max="100" />
        </div>
      ))}
    </Container>
  );
};

export default HeroSectionManagement;