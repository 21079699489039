import React, { useEffect } from 'react';
import { getCookie } from '../utils/cookieUtils';

const MatomoTracker = () => {
  useEffect(() => {
    const initializeMatomo = () => {
      var _paq = window._paq = window._paq || [];
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="//heartport.org/matomo/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '1']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    };

    if (getCookie('cookiesAccepted') === 'true') {
      initializeMatomo();
    }
  }, []);

  return null;
};

export default MatomoTracker;