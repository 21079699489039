import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import { Plus, Edit, Trash2 } from 'lucide-react';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Button = styled.button`
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #2563eb;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
`;

const Thumbnail = styled.img`
  width: 100px;
  height: 56px;
  object-fit: cover;
  border-radius: 0.25rem;
`;

const ActionButton = styled.button`
  background-color: ${props => props.color};
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 80%;
  max-width: 500px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
`;

const Textarea = styled.textarea`
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
  resize: vertical;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const LanguageColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const LanguageHeader = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const HeroSectionSubElementManagement = () => {
  const [subElements, setSubElements] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSubElement, setCurrentSubElement] = useState(null);
  const backendUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchSubElements();
  }, []);

  const fetchSubElements = async () => {
    try {
      const response = await api.get('/api/cms/hero-section-sub-element/get-all');
      setSubElements(response.data);
    } catch (error) {
      console.error('Error fetching hero section sub-elements:', error);
    }
  };

  const openModal = (subElement = null) => {
    setCurrentSubElement(subElement);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentSubElement(null);
    setIsModalOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    // Set default values for all fields when editing
    if (currentSubElement) {
      for (let key in currentSubElement) {
        if (!formData.has(key) && key !== 'id' && key !== 'thumbnailUrl') {
          formData.set(key, currentSubElement[key] || '');
        }
      }
    }

    formData.set('active', formData.get('active') === 'on');
    formData.set('comingSoon', formData.get('comingSoon') === 'on');

    try {
      if (currentSubElement) {
        await api.put(`/api/cms/hero-section-sub-element/${currentSubElement.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        await api.post('/api/cms/hero-section-sub-element', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
      fetchSubElements();
      closeModal();
    } catch (error) {
      console.error('Error saving hero section sub-element:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this sub-element?')) {
      try {
        await api.delete(`/api/cms/hero-section-sub-element/${id}`);
        fetchSubElements();
      } catch (error) {
        console.error('Error deleting hero section sub-element:', error);
      }
    }
  };

  return (
    <Container>
      <Header>
        <Title>Hero Section Sub-Element Management</Title>
        <Button onClick={() => openModal()}>
          <Plus size={20} />
          Create New Sub-Element
        </Button>
      </Header>
      <Table>
        <thead>
          <tr>
            <Th>ID</Th>
            <Th>Thumbnail</Th>
            <Th>German Content</Th>
            <Th>English Content</Th>
            <Th>External URL</Th>
            <Th>Active</Th>
            <Th>Coming Soon</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {subElements.map((subElement) => (
            <tr key={subElement.id}>
              <Td>{subElement.id}</Td>
              <Td>
                <Thumbnail src={`${backendUrl}${subElement.thumbnailUrl}`} alt="Thumbnail" />
              </Td>
              <Td>
                <strong>Headline:</strong> {subElement.headline}<br/>
                <strong>Description:</strong> {subElement.description}
              </Td>
              <Td>
                <strong>Headline:</strong> {subElement.headlineEN}<br/>
                <strong>Description:</strong> {subElement.descriptionEN}
              </Td>
              <Td>{subElement.externalUrl}</Td>
              <Td>{subElement.active ? 'Yes' : 'No'}</Td>
              <Td>{subElement.comingSoon ? 'Yes' : 'No'}</Td>
              <Td>
                <ActionButton color="#3b82f6" onClick={() => openModal(subElement)}>
                  <Edit size={16} />
                </ActionButton>
                <ActionButton color="#ef4444" onClick={() => handleDelete(subElement.id)}>
                  <Trash2 size={16} />
                </ActionButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
      {isModalOpen && (
        <Modal>
          <ModalContent>
            <h2>{currentSubElement ? 'Edit Sub-Element' : 'Create New Sub-Element'}</h2>
            <Form onSubmit={handleSubmit}>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <LanguageColumn>
                  <LanguageHeader>German Content</LanguageHeader>
                  <Input
                    name="headline"
                    placeholder="Headline (DE)"
                    defaultValue={currentSubElement?.headline}
                  />
                  <Textarea
                    name="description"
                    placeholder="Description (DE)"
                    defaultValue={currentSubElement?.description}
                  />
                </LanguageColumn>
                <LanguageColumn>
                  <LanguageHeader>English Content</LanguageHeader>
                  <Input
                    name="headlineEN"
                    placeholder="Headline (EN)"
                    defaultValue={currentSubElement?.headlineEN}
                  />
                  <Textarea
                    name="descriptionEN"
                    placeholder="Description (EN)"
                    defaultValue={currentSubElement?.descriptionEN}
                  />
                </LanguageColumn>
              </div>
              <Input
                name="externalUrl"
                placeholder="External URL- INCLUDING https://"
                defaultValue={currentSubElement?.externalUrl}
              />
              <div>
                <Checkbox
                  type="checkbox"
                  name="active"
                  defaultChecked={currentSubElement?.active}
                />
                <label>Active</label>
              </div>
              <div>
                <Checkbox
                  type="checkbox"
                  name="comingSoon"
                  defaultChecked={currentSubElement?.comingSoon}
                />
                <label>Coming Soon</label>
              </div>
              <Input
                type="file"
                name="image"
                accept="image/*"
              />
              <Button type="submit">Save</Button>
              <Button onClick={closeModal} style={{ backgroundColor: '#6b7280' }}>Cancel</Button>
            </Form>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

export default HeroSectionSubElementManagement;