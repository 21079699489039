import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import axios from 'axios';
import logoSmall from '../assets/images/logo128.png';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLanguage } from '../contexts/LanguageContext';

const GalleryContainer = styled.div`
  background-color: #90C4D9;
  width: 100%;
  box-sizing: border-box;
  padding-top: 3rem; // Half of the Title's height
  @media (max-width: 768px) {
    padding-top: 1.25rem;
  }
`;

const PageWrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const TitleWrapper = styled.div`
  position: relative;
  z-index: 1;
  background: transparent;
  margin-bottom: -0.21rem;

  @media (max-width: 1560px) {
    margin-bottom: -1.0rem;
  }
  @media (max-width: 1150px) {
    margin-bottom: -1.5rem;
  }
`;

const Title = styled.h1`
  color: #4aa3d9;
  font-size: 7rem;
  margin: 0;
  padding: 0;
  text-align: center;
  letter-spacing: 0.1em;
  line-height: 1;
  height: 5.875rem;
  overflow: hidden;
  background: transparent;
  display: inline-block;
  width: 100%;

  @media (max-width: 1560px) {
    font-size: 5rem;
    height: 5rem;
  }

  @media (max-width: 1150px) {
    font-size: 1.5rem;
    height: 2.5rem;
  }
`;


const GallerySection = styled.section`
  margin-bottom: 30px;
  margin-top: 50px;

  padding: 20px;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0px;

  }
`;

const MediaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  @media (max-width: 768px) {
    display: grid;
    grid-template-areas: 
      "text"
      "main"
      "preview";
    gap: 0.25rem;
    width: 100%;
    justify-items: center;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;

  }
`;

const MainVideo = styled.div`
  width: 65%;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  @media (max-width: 768px) {
    width: 100%;
    grid-area: main;
    max-width: 100vw;
  }
`;

const MainFoto = styled.div`
  width: 65%;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  height: 600px;

  @media (max-width: 768px) {
    width: 100%;
    grid-area: main;
    height: 60vh;
    max-height: calc(100vh - 40px);
    padding: 0;
  }
`;

const MainImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (max-width: 768px) {
    max-height: calc(100vh - 40px);
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    overflow: hidden;

  }
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;

  @media (max-width: 768px) {
    max-height: 100vh;
    max-width: 100%;
    height: auto;
  }
`;

const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;

  @media (max-width: 768px) {
    width: 100%;
    display: contents;
  }
`;

const TextLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 40px 0px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 20px 0;
    grid-area: text;
    text-align: center;
  }
`;

const SectionText = styled.p`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
  align-self: flex-start;
  margin-left: 0px;

  @media (max-width: 768px) {
    font-size: 1rem;
    width: 100%;
    align-self: center;
    margin-left: 0;
  }
`;

const Logo = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const GalleryType = styled.h3`
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 25px;
  align-self: flex-end;
  margin-right: 0px;

  @media (max-width: 768px) {
    font-size: 1rem;
    align-self: center;
    margin-right: 0;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-area: preview;
    gap: 0;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
`;

const PreviewMedia = styled.div`
  width: 100%;
  height: auto;
  max-height: 135px;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  overflow: hidden;

  @media (max-width: 768px) {
    width: calc(100% - 48px); // Subtracting space for the ChevronButton
    height: auto;
    max-height: 90px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  object-fit: contain;

  @media (max-width: 768px) {
    max-height: 70vh;
  }
`;

const ChevronButton = styled.button`
width: 250px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const MediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Description = styled.p`
  font-size: 0.8rem;
  color: #333;
  margin-top: 10px;
  text-align: left;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
`;

const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

const VideoWithPoster = ({ src, onClick }) => {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const handleLoadedMetadata = () => {
        video.currentTime = 1; // Seek to 1 second
      };

      const handleSeeked = () => {
        setIsLoaded(true);
      };

      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.addEventListener('seeked', handleSeeked);

      return () => {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        video.removeEventListener('seeked', handleSeeked);
      };
    }
  }, [src]);

  return (
    <div onClick={onClick} style={{ width: '100%', height: '100%' }}>
      <video 
        ref={videoRef}
        src={src}
        poster={src}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          opacity: isLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out'
        }}
      />
    </div>
  );
};

const HeartsGallery = () => {
  const [videos, setVideos] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const { language } = useLanguage();
  const intl = useIntl();
  const [isMobileDevice] = useState(isMobile());

  useEffect(() => {
    fetchGalleryItems();
  }, []);

  const fetchGalleryItems = async () => {
    try {
      const videosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/cms/gallery/public/videos`);
      const photosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/cms/gallery/public/fotos`);
      setVideos(Array.isArray(videosResponse.data) ? videosResponse.data : []);
      setPhotos(Array.isArray(photosResponse.data) ? photosResponse.data : []);
    } catch (error) {
      console.error('Error fetching gallery items:', error);
      setVideos([]);
      setPhotos([]);
    }
  };

  const handleVideoNav = (direction) => {
    setCurrentVideoIndex((prevIndex) => {
      if (videos.length === 0) return 0;
      if (direction === 'next') {
        return (prevIndex + 1) % videos.length;
      } else {
        return prevIndex === 0 ? videos.length - 1 : prevIndex - 1;
      }
    });
  };

  const handlePhotoNav = (direction) => {
    setCurrentPhotoIndex((prevIndex) => {
      if (photos.length === 0) return 0;
      if (direction === 'next') {
        return (prevIndex + 1) % photos.length;
      } else {
        return prevIndex === 0 ? photos.length - 1 : prevIndex - 1;
      }
    });
  };

  const getDescription = (item) => {
    if (!item) return '';
    return language === 'en' ? (item.descriptionEN || item.description || '') : (item.description || item.descriptionEN || '');
  };

  const renderVideoSection = () => {
    if (videos.length === 0) return null;
  
    const currentVideo = videos[currentVideoIndex] || {};
    const nextVideoIndex = (currentVideoIndex + 1) % videos.length;
    const nextVideo = videos[nextVideoIndex] || {};
  
    return (
      <GallerySection>
        <MediaContainer>
          <MainVideo>
            {currentVideo.mediaUrl && (
              <Video controls key={currentVideo.id}>
                <source src={`${process.env.REACT_APP_API_URL}${currentVideo.mediaUrl}`} type="video/mp4" />
                Your browser does not support the video tag.
              </Video>
            )}
            <Description>{getDescription(currentVideo)}</Description>
          </MainVideo>
          <SideContent>
            <TextLogoContainer>
              <SectionText>
                <FormattedMessage id="gallery.videoSection.title" defaultMessage="HEARTS in Sync" />
              </SectionText>
              <Logo src={logoSmall} alt="Hearts Logo" />
              <GalleryType>
                <FormattedMessage id="gallery.videoSection.type" defaultMessage="Video Gallery" />
              </GalleryType>
            </TextLogoContainer>
            <div>
              <PreviewContainer>
                {!isMobileDevice && nextVideo.mediaUrl && (
                  <PreviewMedia>
                    <VideoWithPoster 
                      src={`${process.env.REACT_APP_API_URL}${nextVideo.mediaUrl}`}
                      onClick={() => handleVideoNav('next')}
                    />
                  </PreviewMedia>
                )}
                <ChevronButton onClick={() => handleVideoNav('next')}>
                  <ChevronRight size={48} color="#4aa3d9" />
                </ChevronButton>
              </PreviewContainer>
              {!isMobileDevice && (
                <Description>{getDescription(nextVideo)}</Description>
              )}
            </div>
          </SideContent>
        </MediaContainer>
      </GallerySection>
    );
  };

  const renderPhotoSection = () => {
    if (photos.length === 0) return null;

    const currentPhoto = photos[currentPhotoIndex] || {};
    const nextPhotoIndex = currentPhotoIndex === 0 ? photos.length - 1 : (currentPhotoIndex - 1) % photos.length;
    const nextPhoto = photos[nextPhotoIndex] || {};

    return (
      <GallerySection>
        <MediaContainer>
          <SideContent>
            <TextLogoContainer>
              <SectionText>
                <FormattedMessage id="gallery.photoSection.title" defaultMessage="HEARTS per HEARTBEAT" />
              </SectionText>
              <Logo src={logoSmall} alt="Hearts Logo" />
              <GalleryType>
                <FormattedMessage id="gallery.photoSection.type" defaultMessage="Foto Gallery" />
              </GalleryType>
            </TextLogoContainer>
            <PreviewContainer>
              <ChevronButton onClick={() => handlePhotoNav('prev')}>
                <ChevronLeft size={48} color="#4aa3d9" />
              </ChevronButton>
              {!isMobileDevice && nextPhoto.mediaUrl && (
                <MediaWrapper>
                  <PreviewMedia onClick={() => handlePhotoNav('prev')}>
                    <Image src={`${process.env.REACT_APP_API_URL}${nextPhoto.mediaUrl}`} alt="Preview" />
                  </PreviewMedia>
                  <Description>{getDescription(nextPhoto)}</Description>
                </MediaWrapper>
              )}
            </PreviewContainer>
          </SideContent>
          <MainFoto>
            <MainImageWrapper>
              {currentPhoto.mediaUrl && (
                <MainImage src={`${process.env.REACT_APP_API_URL}${currentPhoto.mediaUrl}`} alt="Main" />
              )}
            </MainImageWrapper>
            <Description>{getDescription(currentPhoto)}</Description>
          </MainFoto>
        </MediaContainer>
      </GallerySection>
    );
  };

  return (
    <>
      {(videos.length > 0 || photos.length > 0) && (
        <PageWrapper>
          <TitleWrapper>
            <Title>
              <FormattedMessage id="gallery.mainTitle" defaultMessage="HEARTS in HEARTBEATS" />
            </Title>
          </TitleWrapper>
          <GalleryContainer>
            {videos.length > 0 && renderVideoSection()}
            {photos.length > 0 && renderPhotoSection()}
          </GalleryContainer>
        </PageWrapper>
      )}
    </>
  );
};

export default HeartsGallery;
