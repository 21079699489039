import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import HeroSectionManagement from './HeroSectionManagement';
import ConferenceManagement from './ConferenceManagement';
import PartnerSectionManagement from './PartnerSectionManagement';
import GeneralEventManagement from './GeneralEventManagement';
import GalleryManagement from './GalleryManagement';
import HeroSectionSubElementManagement from './HeroSectionSubElementManagement';
import FormItemManagement from './FormItemManagement';
import BusinessPartnerGalleryManagement from './BusinessPartnerGalleryManagement';
import VideoWithTextManagement from './VideoWithTextManagement';
import ImpressumManagement from './ImpressumManagement';

const Container = styled.div`
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 1rem;
`;

const Title = styled.h1`
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`;

const ContentManagement = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [conferenceKey, setConferenceKey] = useState(0);
  const [eventKey, setEventKey] = useState(0);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      await api.get('/api/protected');
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error checking auth status:', error);
      setIsLoggedIn(false);
      navigate('/admin/login');
    }
  };

  const handleEventUpdate = useCallback(() => {
    setConferenceKey(prevKey => prevKey + 1);
  }, []);

  const handleConferenceUpdate = useCallback(() => {
    setEventKey(prevKey => prevKey + 1);
  }, []);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Container>
      <Title>Content Management</Title>
      
      <HeroSectionManagement />

      <HeroSectionSubElementManagement />

      <ConferenceManagement 
        key={`conference-${conferenceKey}`}
        onUpdate={handleConferenceUpdate}
      />

      <GeneralEventManagement 
        key={`event-${eventKey}`}
        onUpdate={handleEventUpdate}
      />

      <PartnerSectionManagement />

      <GalleryManagement />

      <FormItemManagement />

      <BusinessPartnerGalleryManagement />

      <VideoWithTextManagement />

      <ImpressumManagement />

    </Container>
  );
};

export default ContentManagement;