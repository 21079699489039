import React from 'react';
import styled from 'styled-components';
import { useLanguage } from '../contexts/LanguageContext';
import { FormattedMessage } from 'react-intl';

const SectionWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.3) 100%);
`;

const TextContent = styled.div`
  max-width: 90%;
  margin-left: 5%;
  margin-top: 3%;
  text-align: left;

  @media (max-width: 768px) {
    margin-top: 5%;
  }
`;

const MainText = styled.div`
  font-size: 1.8rem;
  line-height: 1.3;
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: 10px;
  }
`;

const SmallerText = styled.p`
  font-size: 1.4rem;
  margin-top: 10px;

  @media (max-width: 1200px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
    margin-top: 5px;
  }
`;

const BlueHeadline = styled.h2`
  color: #49ABDB;
  font-size: 7.5rem;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;

  @media (max-width: 1400px) {
    font-size: 5.5rem;
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
    top: 60%;
  }
`;

const VideoSection1 = ({ videoContent = {} }) => {
  const { language } = useLanguage();

  if (!videoContent || Object.keys(videoContent).length === 0) {
    return null;
  }

  // Helper function to get language-specific content
  const getContent = (defaultContent, enContent) => {
    return language === 'en' && enContent ? enContent : defaultContent;
  };

  const description = getContent(videoContent.description, videoContent.descriptionEN) || '';
  const subtexts = getContent(videoContent.subtexts, videoContent.subtextsEN) || [];
  const headline = getContent(videoContent.headline, videoContent.headlineEN) || '';
  const mediaUrl = videoContent.mediaUrl || '';

  return (
    <SectionWrapper>
      {mediaUrl && (
        <VideoBackground autoPlay loop muted playsInline>
          <source src={`${process.env.REACT_APP_API_URL || ''}${mediaUrl}`} type="video/mp4" />
          <FormattedMessage id="video.unsupported" defaultMessage="Your browser does not support the video tag." />
        </VideoBackground>
      )}
      <ContentOverlay>
        <TextContent>
          {description && <MainText>{description}</MainText>}
          {subtexts.map((subtext, index) => (
            <SmallerText key={index}>{subtext}</SmallerText>
          ))}
        </TextContent>
        {headline && <BlueHeadline>{headline}</BlueHeadline>}
      </ContentOverlay>
    </SectionWrapper>
  );
};

export default VideoSection1;