import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../utils/api'; // Adjust the import path as needed
import { useLanguage } from '../contexts/LanguageContext'; // Adjust the import path as needed
import { FormattedMessage } from 'react-intl';

const Container = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 1rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
`;

const Content = styled.div`
  white-space: pre-wrap;
  font-size: 1rem;
  line-height: 1.5;
  color: #444;
`;


const ImpressumDisplay = () => {
  const [impressum, setImpressum] = useState({ defaultContent: '', englishContent: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { language } = useLanguage();

  useEffect(() => {
    const fetchImpressum = async () => {
      try {
        const response = await api.get('/api/cms/impressum/public');
        if (response && response.data) {
          setImpressum({
            defaultContent: response.data.defaultContent || '',
            englishContent: response.data.englishContent || ''
          });
        } else {
          throw new Error('Invalid response data');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching impressum:', err);
        setError('Failed to load Impressum. Please try again later.');
        setLoading(false);
      }
    };

    fetchImpressum();
  }, []);

  const content = language === 'de' ? impressum.defaultContent : impressum.englishContent;
  const title = language === 'de' ? 'Impressum' : 'Imprint';

  return (
    <Container>
      <Title>{title}</Title>
      {loading && <></>}
      {error && <></>}
      {!loading && !error && content && <Content>{content}</Content>}
      {!loading && !error && !content && (<></>)}
    </Container>
  );
};

export default ImpressumDisplay;