import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

const StatCard = styled.div`
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
`;

const StatTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const StatValue = styled.p`
  font-size: 2rem;
  font-weight: bold;
  color: #3b82f6;
`;

const ChartContainer = styled.div`
  height: 300px;
  margin-bottom: 3rem;
`;


const DownloadButton = styled.button`
  background-color: #10b981;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const Th = styled.th`
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
`;

const DeleteButton = styled.button`
  background-color: #ef4444;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  &:hover {
    background-color: #dc2626;
  }
`;

const UserStatistics = () => {
  const [statistics, setStatistics] = useState(null);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const pageSize = 10;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        await fetchUserStatistics();
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length >= 3) {
        searchUsers(searchTerm);
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchUserStatistics = async () => {
    const response = await api.get('/api/cms/users/statistics');
    console.log('Fetched user statistics:', response.data);
    setStatistics(response.data);
  };

  const searchUsers = async (term) => {
    try {
      const response = await api.get(`/api/cms/users/search?term=${term}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching users:', error);
      setError('Failed to search users. Please try again later.');
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await api.delete(`/api/cms/users/${userId}`);
      setSearchResults(prevResults => prevResults.filter(user => user.id !== userId));
      await fetchUserStatistics();
    } catch (error) {
      console.error('Error deleting user:', error);
      setError('Failed to delete user. Please try again later.');
    }
  };

  const prepareChartData = (data) => {
    return Object.entries(data || {}).map(([name, value]) => ({ name, value }));
  };

  const downloadCSV = async () => {
    try {
      const response = await api.get('/api/cms/users/export-csv', {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'users.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading CSV:', error);
      setError('Failed to download CSV. Please try again later.');
    }
  };

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <Container>
      <Header>
        <Title>User Statistics</Title>
      </Header>


      {statistics && (
        <>
          <DownloadButton onClick={downloadCSV}>Download User Data as CSV</DownloadButton>

          <StatCard>
            <StatTitle>Total Users</StatTitle>
            <StatValue>{statistics.totalUsers || 0}</StatValue>
          </StatCard>

          <ChartContainer>
            <StatTitle>Users by Country</StatTitle>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={prepareChartData(statistics.usersByCountry)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#3b82f6" />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>

          <ChartContainer>
            <StatTitle>Users by Company</StatTitle>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={prepareChartData(statistics.usersByCompany)}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#10b981" />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>

          <SearchInput
            type="text"
            placeholder="Search users by name or email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          {searchResults.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <Th>Name</Th>
                  <Th>Email</Th>
                  <Th>Action</Th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((user) => (
                  <tr key={user.id}>
                    <Td>{`${user.firstName} ${user.lastName}`}</Td>
                    <Td>{user.email}</Td>
                    <Td>
                      <DeleteButton onClick={() => handleDeleteUser(user.id)}>
                        Delete
                      </DeleteButton>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </>
      )}


    </Container>
  );
};

export default UserStatistics;

