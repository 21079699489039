import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useIntl } from 'react-intl';
import { useLanguage } from '../contexts/LanguageContext';

const SectionWrapper = styled.section`
  padding: 40px 0;
  background: transparent;
  text-align: center;
`;

const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0px;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0;
  }
`;

const SectionTitle = styled.h2`
  font-size: 3.5rem;
  color: #000;
  margin-bottom: 30px;
  text-transform: uppercase;
  display: inline-block;
  padding: 10px 20px 20px;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const LogoSlider = styled.div`
  overflow: hidden;
  position: relative;
`;

const slide = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
`;

const LogoTrack = styled.div`
  display: flex;
  width: fit-content;
  animation: ${slide} 30s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
`;

const Logo = styled.img`
  height: 80px;
  margin: 0 20px;
`;

const PartnerSection = ({ section = {} }) => {
  const [duplicatedLogos, setDuplicatedLogos] = useState([]);
  const trackRef = useRef(null);
  const API_URL = process.env.REACT_APP_API_URL || '';
  const { language } = useLanguage();
  const intl = useIntl();

  useEffect(() => {
    const duplicateLogos = () => {
      const logos = Array.isArray(section.logos) ? section.logos : [];
      let duplicated = [...logos];
      while (duplicated.length < 10) {
        duplicated = [...duplicated, ...logos];
      }
      setDuplicatedLogos(duplicated);
    };

    duplicateLogos();
  }, [section.logos]);

  // Helper function to get language-specific content
  const getContent = (defaultContent, enContent) => {
    return language === 'en' && enContent ? enContent : defaultContent || '';
  };

  const renderLogo = (logo, index, isDuplicate = false) => {
    if (!logo || typeof logo !== 'object') return null;

    const { id, mediaUrl, altText, altTextEN } = logo;
    const key = `${id || index}-${isDuplicate ? 'duplicate' : 'original'}`;
    const src = mediaUrl ? `${API_URL}${mediaUrl}` : '';
    const alt = getContent(altText, altTextEN) || intl.formatMessage({ id: 'partners.logoAlt', defaultMessage: 'Partner logo' });

    return src ? (
      <Logo 
        key={key}
        src={src}
        alt={alt}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = 'path/to/fallback/image.png'; // Add a fallback image path
        }}
      />
    ) : null;
  };

  return (
    <SectionWrapper>
      <Container>
        <SectionTitle>
          {getContent(section.headline, section.headlineEN)}
        </SectionTitle>
        <LogoSlider>
          <LogoTrack ref={trackRef}>
            {duplicatedLogos.map((logo, index) => renderLogo(logo, index))}
          </LogoTrack>
        </LogoSlider>
      </Container>
    </SectionWrapper>
  );
};

export default PartnerSection;