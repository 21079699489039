import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import styled from 'styled-components';

const PickerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LanguageButton = styled.button`
  background: transparent;
  border: none;
  color: ${props => props.active ? '#47ABDB' : 'white'};
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  transition: color 0.3s ease;
  font-size: 1.8rem;
  &:hover {
    color: #47ABDB;
  }
  
  &:focus {
    outline: none;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const LanguagePicker = () => {
  const languageContext = useLanguage();
  const language = languageContext?.language || 'en';
  const setLanguage = languageContext?.setLanguage || (() => {});

  const handleLanguageChange = (newLanguage) => {
    if (typeof setLanguage === 'function') {
      setLanguage(newLanguage);
    }
  };

  return (
    <PickerWrapper>
      <LanguageButton
        onClick={() => handleLanguageChange('de')}
        active={language === 'de'}
      >
        DE
      </LanguageButton>
      <LanguageButton
        onClick={() => handleLanguageChange('en')}
        active={language === 'en'}
      >
        EN
      </LanguageButton>
    </PickerWrapper>
  );
};

export default LanguagePicker;