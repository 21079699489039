import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

const ContactWrapper = styled.div`
  padding: 2rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  gap: 1rem;
`;

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const intl = useIntl();

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <ContactWrapper>
      <h1><FormattedMessage id="contact.title" /></h1>
      <Form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={intl.formatMessage({ id: 'contact.name' })}
          required
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={intl.formatMessage({ id: 'contact.email' })}
          required
        />
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={intl.formatMessage({ id: 'contact.message' })}
          required
        ></textarea>
        <button type="submit">
          <FormattedMessage id="contact.submit" />
        </button>
      </Form>
    </ContactWrapper>
  );
};

export default Contact;