import React from 'react';
import styled from 'styled-components';
import { useLanguage } from '../contexts/LanguageContext';
import { FormattedMessage } from 'react-intl';

const SectionWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 90vh;
  overflow: hidden;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  background: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.3) 100%);
`;

const Headline = styled.h2`
  color: #49ABDB;
  font-size: 6.0rem;
  text-align: center;
  margin-top: 3rem;
  margin-left: 7%;
  margin-right: 7%;

  @media (max-width: 1400px) {
    font-size: 3rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7.5%;
  margin-right: 30%;
  margin-top: 5rem;
  margin-bottom: 30px;
`;

const Description = styled.div`
  font-size: 1.8rem;
  line-height: 1.3;
  color: #D69DE6;
  margin-bottom: 2rem;

  @media (max-width: 1200px) {
    font-size: 1.3rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Subtexts = styled.div`
  display: flex;
  flex-direction: column;
  bottom: 0;
`;

const Subtext = styled.p`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: ${props => props.isPrimary ? '#D69DE6' : 'white'};
  font-weight: ${props => props.isPrimary ? 'normal' : 'bold'};

  @media (max-width: 1200px) {
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const VideoSection2 = ({ videoContent }) => {
  const { language } = useLanguage();

  if (!videoContent) return null;

  const getContent = (defaultContent, enContent) => {
    return language === 'en' && enContent ? enContent : defaultContent;
  };

  const mediaUrl = videoContent.mediaUrl ? `${process.env.REACT_APP_API_URL || ''}${videoContent.mediaUrl}` : '';
  const headline = getContent(videoContent.headline, videoContent.headlineEN);
  const description = getContent(videoContent.description, videoContent.descriptionEN);
  const subtexts = getContent(videoContent.subtexts, videoContent.subtextsEN) || [];

  return (
    <SectionWrapper>
      {mediaUrl && (
        <VideoBackground autoPlay loop muted playsInline>
          <source src={mediaUrl} type="video/mp4" />
          <FormattedMessage id="video.unsupported" defaultMessage="Your browser does not support the video tag." />
        </VideoBackground>
      )}
      <ContentOverlay>
        {headline && <Headline>{headline}</Headline>}
        <TextContent>
          {description && <Description>{description}</Description>}
          {subtexts.length > 0 && (
            <Subtexts>
              {subtexts.map((subtext, index) => (
                <Subtext key={index} isPrimary={index === 0}>
                  {subtext}
                </Subtext>
              ))}
            </Subtexts>
          )}
        </TextContent>
      </ContentOverlay>
    </SectionWrapper>
  );
};

export default VideoSection2;
