import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import { Plus, X, Check, X as Cross } from 'lucide-react';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const Th = styled.th`
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
`;

const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  gap: 0.25rem;
`;

const LogoWrapper = styled.div`
  position: relative;
  width: 5.76rem;
  height: 3.24rem;
  &:hover button {
    opacity: 1;
  }
`;

const Logo = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${props => `url(${props.src})`};
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.25rem;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ef4444;
  color: white;
  padding: 0.125rem;
  border-radius: 0 0 0 0.25rem;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
  z-index: 10;
`;

const ToggleActiveButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${props => props.isActive ? '#10B981' : '#6B7280'};
  color: white;
  padding: 0.125rem;
  border-radius: 0.25rem 0 0 0;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
  z-index: 10;
`;

const AddMediaLabel = styled.label`
  cursor: pointer;
`;

const AddMediaButton = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border: 2px dashed #d1d5db;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9ca3af;

  &:hover {
    color: #6b7280;
  }
`;

const BusinessPartnerGalleryManagement = () => {
  const [partners, setPartners] = useState([]);
  const backendUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchBusinessPartners();
  }, []);

  const fetchBusinessPartners = async () => {
    try {
      const response = await api.get('/api/cms/business-partner/get-all');
      setPartners(response.data);
    } catch (error) {
      console.error('Error fetching business partners:', error);
    }
  };

  const addBusinessPartners = async (files) => {
    for (let file of files) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('active', 'true');

        const response = await api.post('/api/cms/business-partner', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        setPartners(prevPartners => [...prevPartners, response.data]);
      } catch (error) {
        console.error('Error adding business partner:', error);
      }
    }
  };

  const removeBusinessPartner = async (id) => {
    try {
      await api.delete(`/api/cms/business-partner/${id}`);
      setPartners(partners.filter(partner => partner.id !== id));
    } catch (error) {
      console.error('Error removing business partner:', error);
    }
  };

  const toggleActiveStatus = async (id, currentStatus) => {
    try {
      const formData = new FormData();
      formData.append('active', (!currentStatus).toString());

      const response = await api.put(`/api/cms/business-partner/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      setPartners(partners.map(partner => 
        partner.id === id ? response.data : partner
      ));
    } catch (error) {
      console.error('Error toggling business partner status:', error);
    }
  };

  const renderLogo = (partner) => {
    const logoUrl = `${backendUrl}${partner.mediaUrl}`;
    return (
      <LogoWrapper key={partner.id}>
        <Logo src={logoUrl} />
        <RemoveButton onClick={() => removeBusinessPartner(partner.id)}>
          <X size={8} />
        </RemoveButton>
        <ToggleActiveButton 
          isActive={partner.active}
          onClick={() => toggleActiveStatus(partner.id, partner.active)}
        >
          {partner.active ? <Check size={8} /> : <Cross size={8} />}
        </ToggleActiveButton>
      </LogoWrapper>
    );
  };

  return (
    <Container>
      <Header>
        <Title>Business Partner Management</Title>
      </Header>
      <Table>
        <thead>
          <tr>
            <Th>Partner Logos</Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td>
              <MediaContainer>
                {partners.map(partner => renderLogo(partner))}
                <AddMediaLabel>
                  <input 
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => addBusinessPartners(e.target.files)}
                    style={{ display: 'none' }}
                  />
                  <AddMediaButton>
                    <Plus size={16} />
                  </AddMediaButton>
                </AddMediaLabel>
              </MediaContainer>
            </Td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default BusinessPartnerGalleryManagement;
