import React, { createContext, useState, useContext } from 'react';

const defaultLanguage = 'de';

const LanguageContext = createContext({
  language: defaultLanguage,
  setLanguage: () => {},
});

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(defaultLanguage);

  const value = {
    language: language || defaultLanguage,
    setLanguage: (newLang) => setLanguage(newLang || defaultLanguage),
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);