import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Menu, X } from 'lucide-react';
import logo from '../assets/images/logo-navbar.png';
import LanguagePicker from '../components/LanguagePicker';

const HeaderWrapper = styled.header`
  background: transparent;
  padding-left: 2rem;
  padding-right: 4rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
  @media (max-width: 768px) {
    padding-right: 2rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled(Link)`
  height: 75px;
  margin-left: 10px;
`;

const LogoImage = styled.img`
  height: 100%;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2.5rem;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 70px;
    right: 0;
    background-color: #90C4D9;
    width: 100%;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10000;
  }
`;

const NavItem = styled.span`
  color: ${({ isActive, isExternal }) => (isActive ? '#47ABDB' : isExternal ? '#888' : 'white')};
  text-decoration: none;
  font-weight: bold;
  cursor: ${({ isExternal }) => (isExternal ? 'default' : 'pointer')};
  font-size: 1.2rem;
  position: relative;
  
  &:hover {
    text-decoration: ${({ isExternal }) => (isExternal ? 'none' : 'underline')};
  }

  &::after {
    content: '.';
    color: #47ABDB;
    margin-left: 2px;
  }

  ${({ isExternal }) => isExternal && `
    &:hover::before {
      content: 'Coming Soon';
      position: absolute;
      background-color: rgba(0, 0, 0, 0.7);
      color: white;
      padding: 5px;
      border-radius: 4px;
      font-size: 0.8rem;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      margin-top: 5px;
    }
  `}
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  
  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const NavLinkWrapper = ({ to, children, external }) => {
    const isActive = location && location.pathname === to;

    if (external) {
      return (
        <NavItem as="span" isExternal={true}>
          {children}
        </NavItem>
      );
    }
    return (
      <NavItem as={Link} to={to || '/'} isActive={isActive}>
        {children}
      </NavItem>
    );
  };

  return (
    <HeaderWrapper>
      <Nav>
        <Logo to="/">
          <LogoImage src={logo || ''} alt="HEARTS Logo" />
        </Logo>
        <LanguagePicker />
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;