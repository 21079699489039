import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getCookie, setCookie } from '../utils/cookieUtils';
import flower from '../assets/images/flower_1.png';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 0px;
  max-width: 500px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const FlowerImage = styled.div`
  position: absolute;
  top: 50%;
  left: -150px;
  transform: translateY(-50%);
  width: 641px;
  height: 742px;
  background-image: url(${flower});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.3;
  z-index: 1;
  pointer-events: none;
  @media (max-width: 768px) {
    opacity: 0.1;
  }
`;

const ContentWrapper = styled.div`
  padding: 20px;
  position: relative;
  z-index: 2;
`;

const Title = styled.h2`
  margin: 0 0 20px 0;
  color: #333;
`;

const Message = styled.p`
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1.5rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Button = styled.button`
  background-color: ${props => props.primary ? '#47ABDB' : 'white'};
  color: ${props => props.primary ? 'white' : '#47ABDB'};
  border: ${props => props.primary ? 'none' : '1px solid #47ABDB'};
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 0px;

  &:hover {
    background-color: ${props => props.primary ? '#0056b3' : '#f0f8ff'};
  }
`;

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    try {
      const cookiesAccepted = getCookie('cookiesAccepted');
      if (cookiesAccepted === null) {
        setShowConsent(true);
      }
    } catch (error) {
      console.error('Error checking cookie consent:', error);
      // If there's an error, we show the consent dialog to be safe
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    try {
      setCookie('cookiesAccepted', 'true', 365);
      setShowConsent(false);
      // Trigger Matomo initialization
      if (window._paq && Array.isArray(window._paq)) {
        window._paq.push(['trackPageView']);
        window._paq.push(['enableLinkTracking']);
      }
    } catch (error) {
      console.error('Error setting cookie or initializing analytics:', error);
    }
  };

  const handleReject = () => {
    try {
      setCookie('cookiesAccepted', 'false', 365);
      setShowConsent(false);
    } catch (error) {
      console.error('Error setting cookie:', error);
    }
  };

  if (!showConsent) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <FlowerImage />
        <ContentWrapper>
          <Title>Cookie Consent</Title>
          <Message>
            We use cookies to improve your experience and for analytics. By continuing to use this site, you agree to our use of cookies.
          </Message>
          <ButtonGroup>
            <Button onClick={handleReject}>Reject</Button>
            <Button primary onClick={handleAccept}>Accept</Button>
          </ButtonGroup>
        </ContentWrapper>
      </ModalContent>
    </ModalOverlay>
  );
};

export default CookieConsent;