import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import { Plus, RefreshCw } from 'lucide-react';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const Button = styled.button`
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #2563eb;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const FormItemManagement = () => {
  const [formItems, setFormItems] = useState([]);
  const [editingLabels, setEditingLabels] = useState({});
  const [editingLabelsEN, setEditingLabelsEN] = useState({});

  useEffect(() => {
    fetchFormItems();
  }, []);

  const fetchFormItems = async () => {
    try {
      const response = await api.get('/api/cms/form-item/get-all');
      if (Array.isArray(response.data)) {
        setFormItems(response.data);
      } else {
        console.error('Data fetched is not an array:', response.data);
      }
    } catch (error) {
      console.error('Error fetching form items:', error);
    }
  };

  const createFormItem = async () => {
    try {
      const newFormItem = {
        label: '',
        labelEN: '',
        required: false,
        active: false
      };
      const response = await api.post('/api/cms/form-item', null, {
        params: newFormItem
      });
      setFormItems(prevItems => [...prevItems, response.data]);
    } catch (error) {
      console.error('Error creating form item:', error);
    }
  };

  const updateFormItem = async (id, updatedFields) => {
    try {
      const currentItem = formItems.find(item => item.id === id);
      const updatedItem = { ...currentItem, ...updatedFields };
      
      const response = await api.put(`/api/cms/form-item/${id}`, null, {
        params: {
          required: updatedItem.required,
          label: updatedItem.label,
          labelEN: updatedItem.labelEN,
          active: updatedItem.active
        }
      });
      
      setFormItems(prevItems => 
        prevItems.map(item => item.id === id ? response.data : item)
      );
      return response.data;
    } catch (error) {
      console.error(`Error updating form item ${id}:`, error);
      return null;
    }
  };

  const deleteFormItem = async (id) => {
    try {
      await api.delete(`/api/cms/form-item/${id}`);
      setFormItems(prevItems => prevItems.filter(item => item.id !== id));
    } catch (error) {
      console.error(`Error deleting form item ${id}:`, error);
    }
  };

  const handleLabelChange = (id, newLabel, isEN = false) => {
    if (isEN) {
      setEditingLabelsEN(prev => ({ ...prev, [id]: newLabel }));
    } else {
      setEditingLabels(prev => ({ ...prev, [id]: newLabel }));
    }
  };

  const handleLabelBlur = async (id, isEN = false) => {
    const newLabel = isEN ? editingLabelsEN[id] : editingLabels[id];
    const labelKey = isEN ? 'labelEN' : 'label';
    if (newLabel !== undefined && newLabel !== formItems.find(i => i.id === id)[labelKey]) {
      const currentItem = formItems.find(item => item.id === id);
      const updatedItem = await updateFormItem(id, { 
        [labelKey]: newLabel,
        required: currentItem.required,
        active: currentItem.active
      });
      if (updatedItem) {
        if (isEN) {
          setEditingLabelsEN(prev => {
            const { [id]: _, ...rest } = prev;
            return rest;
          });
        } else {
          setEditingLabels(prev => {
            const { [id]: _, ...rest } = prev;
            return rest;
          });
        }
      } else {
        // If update failed, revert to the original label
        if (isEN) {
          setEditingLabelsEN(prev => {
            const { [id]: _, ...rest } = prev;
            return rest;
          });
        } else {
          setEditingLabels(prev => {
            const { [id]: _, ...rest } = prev;
            return rest;
          });
        }
      }
    } else {
      // If no change, just remove from editing state
      if (isEN) {
        setEditingLabelsEN(prev => {
          const { [id]: _, ...rest } = prev;
          return rest;
        });
      } else {
        setEditingLabels(prev => {
          const { [id]: _, ...rest } = prev;
          return rest;
        });
      }
    }
  };

  const handleRequiredChange = async (id, required) => {
    const currentItem = formItems.find(item => item.id === id);
    const updatedItem = await updateFormItem(id, { 
      required,
      label: currentItem.label,
      labelEN: currentItem.labelEN,
      active: currentItem.active
    });
    if (!updatedItem) {
      // If update failed, revert the checkbox
      setFormItems(prevItems => prevItems.map(item => 
        item.id === id ? { ...item, required: !required } : item
      ));
    }
  };

  const handleActiveChange = async (id, active) => {
    const currentItem = formItems.find(item => item.id === id);
    const updatedItem = await updateFormItem(id, { 
      active,
      label: currentItem.label,
      labelEN: currentItem.labelEN,
      required: currentItem.required
    });
    if (!updatedItem) {
      // If update failed, revert the checkbox
      setFormItems(prevItems => prevItems.map(item => 
        item.id === id ? { ...item, active: !active } : item
      ));
    }
  };

  return (
    <Container>
      <Header>
        <Title>Form Item Management</Title>
        <ButtonGroup>
          <Button onClick={fetchFormItems}>
            <RefreshCw size={20} />
            Refresh
          </Button>
          <Button onClick={createFormItem}>
            <Plus size={20} />
            Create New Form Item
          </Button>
        </ButtonGroup>
      </Header>
      <Table>
        <thead>
          <tr>
            <Th>ID</Th>
            <Th>Label</Th>
            <Th>Label (EN)</Th>
            <Th>Required</Th>
            <Th>Active</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {formItems.map((item) => (
            <tr key={item.id}>
              <Td>{item.id}</Td>
              <Td>
                <Input
                  value={editingLabels[item.id] !== undefined ? editingLabels[item.id] : item.label}
                  onChange={(e) => handleLabelChange(item.id, e.target.value)}
                  onBlur={() => handleLabelBlur(item.id)}
                  placeholder="Enter label"
                />
              </Td>
              <Td>
                <Input
                  value={editingLabelsEN[item.id] !== undefined ? editingLabelsEN[item.id] : item.labelEN}
                  onChange={(e) => handleLabelChange(item.id, e.target.value, true)}
                  onBlur={() => handleLabelBlur(item.id, true)}
                  placeholder="Enter label (EN)"
                />
              </Td>
              <Td>
                <Checkbox 
                  type="checkbox" 
                  checked={item.required}
                  onChange={(e) => handleRequiredChange(item.id, e.target.checked)}
                />
              </Td>
              <Td>
                <Checkbox 
                  type="checkbox" 
                  checked={item.active}
                  onChange={(e) => handleActiveChange(item.id, e.target.checked)}
                />
              </Td>
              <Td>
                <Button onClick={() => deleteFormItem(item.id)} style={{ backgroundColor: '#ef4444' }}>
                  Delete
                </Button>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default FormItemManagement;