import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import axios from 'axios';
import SlideShowComponent from '../components/SlideShowComponent';
import MainEvent from '../components/MainEvent';
import OtherHeartsEvents from '../components/OtherHeartsEvents';
import PartnerSection from '../components/PartnerSection';
import VideoSection1 from '../components/VideoSection1';
import VideoSection2 from '../components/VideoSection2';
import HeartsGallery from '../components/HeartsGallery';
import BusinessPartnersDisplay from '../components/BusinessPartnersDisplay';

const HomeWrapper = styled.div`
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Spacer = styled.div`
  height: ${props => props.size}rem;
`;


const Home = ({ openRegistrationModal }) => {
  const [partnerSections, setPartnerSections] = useState([]);
  const [videoWithTextItems, setVideoWithTextItems] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [partnerResponse, eventsResponse, videoWithTextResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/cms/partner-section/public/get-all-active`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/cms/event/public/get-non-main`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/cms/video-with-text/public/get-all-active`)
        ]);

        setPartnerSections(partnerResponse.data?.filter(section => section?.active) || []);
        setEvents(eventsResponse.data || []);
        setVideoWithTextItems(videoWithTextResponse.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error loading data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderPartnerSection = (index) => {
    const section = partnerSections[index];
    if (section) {
      return (
        <React.Fragment key={section.id}>
          <PartnerSection section={section} />
          <Spacer size={8} />
        </React.Fragment>
      );
    }
    return null;
  };

  if (loading) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <HomeWrapper>
      <SlideShowComponent openRegistrationModal={openRegistrationModal} />
      <Spacer size={8} />
      <MainEvent openRegistrationModal={openRegistrationModal} />
      <Spacer size={8} />
      {events.map((event) => (
        <React.Fragment key={event.id}>
          <OtherHeartsEvents event={event} openRegistrationModal={openRegistrationModal} />
          <Spacer size={8} />
        </React.Fragment>
      ))}
      {renderPartnerSection(0)}
      {videoWithTextItems[0] && <VideoSection1 videoContent={videoWithTextItems[0]} />}
      <Spacer size={8} />
      {renderPartnerSection(1)}
      <HeartsGallery />
      <Spacer size={8} />
      {partnerSections.slice(2).map((section) => (
        <React.Fragment key={section.id}>
          <PartnerSection section={section} />
          <Spacer size={8} />
        </React.Fragment>
      ))}
      <BusinessPartnersDisplay />
      <Spacer size={8} />
      {videoWithTextItems[1] && <VideoSection2 videoContent={videoWithTextItems[1]} />}
      <Spacer size={8} />
    </HomeWrapper>
  );
};

export default Home;