import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import { Save } from 'lucide-react';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Button = styled.button`
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #2563eb;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 200px;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
`;

const Label = styled.label`
  font-weight: bold;
`;

const ImpressumManagement = () => {
  const [impressum, setImpressum] = useState({
    defaultContent: '',
    englishContent: ''
  });

  useEffect(() => {
    fetchImpressum();
  }, []);

  const fetchImpressum = async () => {
    try {
      const response = await api.get('/api/cms/impressum/public');
      console.log('Fetched impressum:', response.data);
      setImpressum(response.data);
    } catch (error) {
      console.error('Error fetching impressum:', error);
    }
  };

  const handleContentChange = (lang, newContent) => {
    setImpressum(prev => ({
      ...prev,
      [lang === 'de' ? 'defaultContent' : 'englishContent']: newContent
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.put('/api/cms/impressum', impressum);
      console.log('Updated impressum:', response.data);
      alert('Impressum updated successfully!');
    } catch (error) {
      console.error('Error updating impressum:', error);
      alert('Failed to update impressum. Please try again.');
    }
  };

  return (
    <Container>
      <Header>
        <Title>Impressum Management</Title>
      </Header>
      <Form onSubmit={handleSubmit}>
        <div>
          <Label htmlFor="defaultContent">Impressum (DE)</Label>
          <TextArea
            id="defaultContent"
            value={impressum.defaultContent}
            onChange={(e) => handleContentChange('de', e.target.value)}
            placeholder="Enter Impressum content in German"
          />
        </div>
        <div>
          <Label htmlFor="englishContent">Impressum (EN)</Label>
          <TextArea
            id="englishContent"
            value={impressum.englishContent}
            onChange={(e) => handleContentChange('en', e.target.value)}
            placeholder="Enter Impressum content in English"
          />
        </div>
        <Button type="submit">
          <Save size={20} />
          Save Changes
        </Button>
      </Form>
    </Container>
  );
};

export default ImpressumManagement;