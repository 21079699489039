import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../utils/authUtils';
import userManagementImage from '../../assets/images/user_management.webp';
import contentManagementImage from '../../assets/images/content_management.webp';

const PageContainer = styled.div`
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

const MainContent = styled.main`
  width: 100%;
  max-width: 800px;
  padding: 2rem;
`;

const DashboardTitle = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
  color: #111827;
  margin-bottom: 2rem;
  text-align: center;
`;

const LinkGrid = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const StyledLink = styled(Link)`
  width: 200px;
  height: 200px;
  background-image: url(${props => props.bgImage});
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const LinkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const LinkText = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  text-align: center;
`;

const AdminDashboard = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      await api.get('/api/protected');
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error checking auth status:', error);
      setIsLoggedIn(false);
      navigate('/admin/login');
    }
  };

  if (!isLoggedIn) {
    return (
      <PageContainer>
        <MainContent>
          <Link to="/" style={{ color: '#3b82f6', textDecoration: 'none' }}>
            Return to Home
          </Link>
        </MainContent>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <MainContent>
        <DashboardTitle>Admin Dashboard</DashboardTitle>
        <LinkGrid>
          <StyledLink to="/admin/user-management" bgImage={userManagementImage}>
            <LinkOverlay>
              <LinkText>User Management</LinkText>
            </LinkOverlay>
          </StyledLink>
          <StyledLink to="/admin/content-management" bgImage={contentManagementImage}>
            <LinkOverlay>
              <LinkText>Content Management</LinkText>
            </LinkOverlay>
          </StyledLink>
        </LinkGrid>
      </MainContent>
    </PageContainer>
  );
};

export default AdminDashboard;