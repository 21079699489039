import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import { Plus, X, Play, Edit } from 'lucide-react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-top: 10px;
`;

const ProgressBarFill = styled.div`
  height: 10px;
  background-color: #4caf50;
  border-radius: 4px;
  width: ${props => props.progress}%;
  transition: width 0.3s ease-in-out;
`;

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  vertical-align: top;
`;

const MediaContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 112px;
  margin-bottom: 0.5rem;
`;

const Thumbnail = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: center;
  border-radius: 0.25rem;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.25rem;
`;

const ButtonBase = styled.button`
  position: absolute;
  color: white;
  padding: 0.25rem;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 50%;
`;

const RemoveButton = styled(ButtonBase)`
  top: 0.25rem;
  right: 0.25rem;
  background-color: #ef4444;

  &:hover {
    background-color: #dc2626;
  }
`;

const EditButton = styled(ButtonBase)`
  top: 0.25rem;
  left: 0.25rem;
  background-color: #3b82f6;

  &:hover {
    background-color: #2563eb;
  }
`;

const AddMediaLabel = styled.label`
  cursor: pointer;
`;

const AddMediaButton = styled.div`
  width: 200px;
  height: 112px;
  border: 2px dashed #d1d5db;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9ca3af;

  &:hover {
    color: #6b7280;
  }
`;

const DescriptionInput = styled.input`
  width: 100%;
  padding: 0.25rem;
  margin-top: 0.25rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
`;

const SaveButton = styled.button`
  margin-top: 0.5rem;
  background-color: #10b981;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;

  &:hover {
    background-color: #059669;
  }
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.25rem;
`;

const VideoThumbnail = ({ src }) => {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const handleLoadedMetadata = () => {
        video.currentTime = 1; // Seek to 1 second
      };

      const handleSeeked = () => {
        setIsLoaded(true);
      };

      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.addEventListener('seeked', handleSeeked);

      return () => {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        video.removeEventListener('seeked', handleSeeked);
      };
    }
  }, [src]);

  return (
    <Video 
      ref={videoRef}
      src={src}
      poster={src}
      style={{
        opacity: isLoaded ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out'
      }}
    />
  );
};

const GalleryManagement = () => {
  const [fotos, setFotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [editingFoto, setEditingFoto] = useState(null);
  const [editingVideo, setEditingVideo] = useState(null);
  const backendUrl = process.env.REACT_APP_API_URL;
  const [uploadQueue, setUploadQueue] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [stompClient, setStompClient] = useState(null);

  useEffect(() => {
    fetchGalleryItems();
    setupWebSocket();
  }, []);

  useEffect(() => {
    const hasUnprocessedUploads = uploadQueue.some(item => !item.processing);
    if (hasUnprocessedUploads && !isUploading) {
      processNextUpload();
    }
  }, [uploadQueue, isUploading]);

  const setupWebSocket = () => {
    const socket = new SockJS(`${backendUrl}/ws`);
    const client = Stomp.over(socket);
    client.debug = () => {};
  
    client.connect({}, 
      () => {
        console.log('WebSocket connected');
        client.subscribe('/topic/upload-progress', (message) => {
          const progress = JSON.parse(message.body);
          setUploadProgress(prev => ({
            ...prev,
            [progress.fileName]: progress.progress
          }));
        });
      },
      (error) => {
        console.error('WebSocket connection error:', error);
      }
    );
    setStompClient(client);
  
    return () => {
      if (client && client.connected) {
        client.disconnect();
      }
    };
  };


  const fetchGalleryItems = async () => {
    try {
      const fotosResponse = await api.get('/api/cms/gallery/public/fotos');
      const videosResponse = await api.get('/api/cms/gallery/public/videos');
      setFotos(fotosResponse.data);
      setVideos(videosResponse.data);
    } catch (error) {
      console.error('Error fetching gallery items:', error);
    }
  };
  
  const addGalleryItems = (files, type) => {
    for (let file of files) {
      setUploadQueue(prevQueue => [...prevQueue, { file, type, processing: false }]);
    }
  };

  const processNextUpload = async () => {
    if (uploadQueue.length === 0) return;
  
    const nextUpload = uploadQueue.find(item => !item.processing);
    if (!nextUpload) return;
  
    const { file, type } = nextUpload;
    
    setUploadQueue(prevQueue => 
      prevQueue.map(item => 
        item === nextUpload ? { ...item, processing: true } : item
      )
    );
  
    try {
      setIsUploading(true);
      const uploadResult = await uploadGalleryItem(file);
      await addGalleryItemToDatabase(
        uploadResult.fileName,
        type,
        '', // description
        '', // descriptionEN
        type === 'foto' ? fotos.length : videos.length // orderIndex
      );
    } catch (error) {
      console.error('Error processing upload:', error);
    } finally {
      setIsUploading(false);
      setUploadQueue(prevQueue => prevQueue.filter(item => item !== nextUpload));
    }
  };

  const addGalleryItemToDatabase = async (fileName, type, description, descriptionEN, orderIndex) => {
    try {
      const params = new URLSearchParams({
        fileName,
        description,
        descriptionEN,
        orderIndex: orderIndex.toString()
      });

      const response = await api.post(`/api/cms/gallery/${type}?${params.toString()}`);

      if (type === 'foto') {
        setFotos(prevFotos => [...prevFotos, response.data]);
      } else {
        setVideos(prevVideos => [...prevVideos, response.data]);
      }
    } catch (error) {
      console.error(`Error adding ${type} to gallery:`, error);
    }
  };


  const uploadGalleryItem = async (file) => {
    try {
      // Initialize progress to 0%
      setUploadProgress(prev => ({
        ...prev,
        [file.name]: 0
      }));
  
      const formData = new FormData();
      formData.append('file', file);
      
      const response = await api.post(`/api/cms/gallery/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(prev => ({
            ...prev,
            [file.name]: percentCompleted
          }));
        }
      });
  
      console.log(`Uploaded file:`, response.data);
      return response.data;
    } catch (error) {
      console.error(`Error uploading file:`, error);
      throw error;
    } finally {
      // Remove progress entry when upload is complete
      setUploadProgress(prev => {
        const newProgress = {...prev};
        delete newProgress[file.name];
        return newProgress;
      });
    }
  };


  const addGalleryItem = async (file, type) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('description', '');
      formData.append('descriptionEN', '');
      formData.append('orderIndex', type === 'foto' ? fotos.length : videos.length);

      const response = await api.post(`/api/cms/gallery/${type}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      if (type === 'foto') {
        setFotos([...fotos, response.data]);
      } else {
        setVideos([...videos, response.data]);
      }
    } catch (error) {
      console.error(`Error adding ${type} to gallery:`, error);
    }
  };

  const removeGalleryItem = async (id, type) => {
    try {
      await api.delete(`/api/cms/gallery/${type}/${id}`);
      if (type === 'foto') {
        setFotos(fotos.filter(foto => foto.id !== id));
      } else {
        setVideos(videos.filter(video => video.id !== id));
      }
    } catch (error) {
      console.error(`Error removing ${type} from gallery:`, error);
    }
  };

  const updateGalleryItem = async (id, type, updatedData) => {
    try {
      const params = new URLSearchParams({
        description: updatedData.description || '',
        descriptionEN: updatedData.descriptionEN || '',
        orderIndex: (updatedData.orderIndex || 0).toString()
      });

      const response = await api.put(`/api/cms/gallery/${type}/${id}?${params.toString()}`);

      if (type === 'foto') {
        setFotos(fotos.map(foto => foto.id === id ? response.data : foto));
        setEditingFoto(null);
      } else {
        setVideos(videos.map(video => video.id === id ? response.data : video));
        setEditingVideo(null);
      }
    } catch (error) {
      console.error(`Error updating ${type} in gallery:`, error);
    }
  };
  
  const handleDescriptionChange = (e, lang, type) => {
    const updateFunction = type === 'foto' ? setEditingFoto : setEditingVideo;
    updateFunction(prev => ({
      ...prev,
      [lang === 'de' ? 'description' : 'descriptionEN']: e.target.value
    }));
  };


  const renderGalleryItem = (item, type) => {
    const thumbnailUrl = `${backendUrl}${item.mediaUrl}`;
    const isEditing = type === 'foto' 
      ? editingFoto && editingFoto.id === item.id
      : editingVideo && editingVideo.id === item.id;
    const editingItem = type === 'foto' ? editingFoto : editingVideo;
  
    return (
      <ItemWrapper key={item.id}>
        <ThumbnailWrapper>
          {type === 'video' ? (
            <VideoThumbnail src={thumbnailUrl} />
          ) : (
            <Thumbnail src={thumbnailUrl} />
          )}
          {type === 'video' && (
            <VideoOverlay>
              <Play size={16} color="white" />
            </VideoOverlay>
          )}
          <RemoveButton onClick={() => removeGalleryItem(item.id, type)}>
            <X size={16} />
          </RemoveButton>
          <EditButton onClick={() => type === 'foto' ? setEditingFoto(item) : setEditingVideo(item)}>
            <Edit size={16} />
          </EditButton>
        </ThumbnailWrapper>
        {isEditing ? (
          <>
            <DescriptionInput
              value={editingItem.description || ''}
              onChange={(e) => handleDescriptionChange(e, 'de', type)}
              placeholder="Description (DE)"
            />
            <DescriptionInput
              value={editingItem.descriptionEN || ''}
              onChange={(e) => handleDescriptionChange(e, 'en', type)}
              placeholder="Description (EN)"
            />
            <SaveButton onClick={() => updateGalleryItem(item.id, type, editingItem)}>Save</SaveButton>
          </>
        ) : (
          <>
            <div>DE: {item.description || 'No description'}</div>
            <div>EN: {item.descriptionEN || 'No description'}</div>
          </>
        )}
      </ItemWrapper>
    );
  };


  return (
    <Container>
      <Header>
        <Title>Gallery Management</Title>
      </Header>
      <Table>
        <thead>
          <tr>
            <Th>Photos</Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td>
              <MediaContainer>
                {fotos.map(foto => renderGalleryItem(foto, 'foto'))}
                <AddMediaLabel>
                  <input 
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => addGalleryItems(e.target.files, 'foto')}
                    style={{ display: 'none' }}
                  />
                  <AddMediaButton>
                    <Plus size={16} />
                  </AddMediaButton>
                </AddMediaLabel>
              </MediaContainer>
            </Td>
          </tr>
        </tbody>
      </Table>
      <Table style={{ marginTop: '2rem' }}>
        <thead>
          <tr>
            <Th>Videos</Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td>
              <MediaContainer>
                {videos.map(video => renderGalleryItem(video, 'video'))}
                <AddMediaLabel>
                  <input 
                    type="file"
                    accept="video/*"
                    multiple
                    onChange={(e) => addGalleryItems(e.target.files, 'video')}
                    style={{ display: 'none' }}
                  />
                  <AddMediaButton>
                    <Plus size={16} />
                  </AddMediaButton>
                </AddMediaLabel>
              </MediaContainer>
            </Td>
          </tr>
        </tbody>
      </Table>
      {Object.entries(uploadProgress).map(([fileName, progress]) => (
        <div key={fileName}>
          <div>{fileName}</div>
          <ProgressBarContainer>
            <ProgressBarFill progress={progress} />
          </ProgressBarContainer>
        </div>
      ))}
    </Container>
  );
};


export default GalleryManagement;