import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import api from '../../../utils/authUtils';
import { Plus, Edit, Trash2, X } from 'lucide-react';
import { format } from 'date-fns';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
`;

const Button = styled.button`
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #2563eb;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #e5e7eb;
  padding: 0.5rem;
`;

const ActionButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;

  &:hover {
    color: #3b82f6;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 80%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
`;

const TextArea = styled.textarea`
  padding: 0.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 0.25rem;
  resize: vertical;
`;

const BulletPointContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const BulletPointInput = styled(Input)`
  flex-grow: 1;
  margin-right: 0.5rem;
`;

const RemoveButton = styled(Button)`
  background-color: #ef4444;
  padding: 0.25rem 0.5rem;

  &:hover {
    background-color: #dc2626;
  }
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
`;

const LanguageColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const LanguageHeader = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

const GeneralEventManagement = ({ onUpdate }) => {
  const [generalEvents, setGeneralEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [formData, setFormData] = useState({
    generalEvent: {
      bigHeadline: '',
      subHeadline: '',
      mainHeadline: '',
      subText: '',
      dateTime: '',
      location: '',
      programText: '',
      isActive: false,
      isMain: false,
      bulletPointsHeadline: {
        headline: '',
        headlineEN: '',
        bulletPoints: [''],
        bulletPointsEN: ['']
      },
      textHeadline: {
        headline: '',
        headlineEN: '',
        textContent: '',
        textContentEN: ''
      },
      bigHeadlineEN: '',
      subHeadlineEN: '',
      mainHeadlineEN: '',
      subTextEN: '',
      programTextEN: ''
    },
    image: null,
    program: null
  });

  useEffect(() => {
    fetchGeneralEvents();
  }, []);

  const fetchGeneralEvents = async () => {
    try {
      const response = await api.get('/api/cms/general-event/get-all');
      const processedEvents = response.data.map(event => ({
        ...event,
        isActive: Boolean(event.isActive),
        isMain: Boolean(event.isMain)
      }));
      console.log("general events fetched: ", processedEvents);
      setGeneralEvents(processedEvents);
    } catch (error) {
      console.error('Error fetching general events:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      generalEvent: {
        ...prev.generalEvent,
        [name]: type === 'checkbox' ? Boolean(checked) : value
      }
    }));
  };

  const handleNestedInputChange = (e, nestedField) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      generalEvent: {
        ...prev.generalEvent,
        [nestedField]: {
          ...prev.generalEvent[nestedField],
          [name]: value
        }
      }
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: files[0]
    }));
  };

  const handleBulletPointChange = (index, value, field) => {
    setFormData(prev => ({
      ...prev,
      generalEvent: {
        ...prev.generalEvent,
        bulletPointsHeadline: {
          ...prev.generalEvent.bulletPointsHeadline,
          [field]: prev.generalEvent.bulletPointsHeadline[field].map((point, i) => 
            i === index ? value : point
          )
        }
      }
    }));
  };

  const addBulletPoint = (field) => {
    setFormData(prev => ({
      ...prev,
      generalEvent: {
        ...prev.generalEvent,
        bulletPointsHeadline: {
          ...prev.generalEvent.bulletPointsHeadline,
          [field]: [...prev.generalEvent.bulletPointsHeadline[field], '']
        }
      }
    }));
  };

  const removeBulletPoint = (index, field) => {
    setFormData(prev => ({
      ...prev,
      generalEvent: {
        ...prev.generalEvent,
        bulletPointsHeadline: {
          ...prev.generalEvent.bulletPointsHeadline,
          [field]: prev.generalEvent.bulletPointsHeadline[field].filter((_, i) => i !== index)
        }
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    const generalEventData = {
      ...formData.generalEvent,
      isActive: Boolean(formData.generalEvent.isActive),
      isMain: Boolean(formData.generalEvent.isMain)
    };
    console.log("generalEventData: ", generalEventData);
    data.append('generalEvent', JSON.stringify(generalEventData));
    if (formData.image) data.append('image', formData.image);
    if (formData.program) data.append('program', formData.program);

    try {
      if (currentEvent) {
        await api.put(`/api/cms/general-event/${currentEvent.id}`, data);
      } else {
        await api.post('/api/cms/general-event', data);
      }
      setShowModal(false);
      setCurrentEvent(null);
      fetchGeneralEvents();
      onUpdate();
    } catch (error) {
      console.error('Error saving general event:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this general event?')) {
      try {
        await api.delete(`/api/cms/general-event/${id}`);
        fetchGeneralEvents();
      } catch (error) {
        console.error('Error deleting general event:', error);
      }
    }
  };

  const openModal = (event = null) => {
    if (event) {
      setFormData({
        generalEvent: {
          ...event,
          dateTime: event.dateTime ? format(new Date(event.dateTime), "yyyy-MM-dd'T'HH:mm") : '',
          isActive: Boolean(event.isActive),
          isMain: Boolean(event.isMain),
          bulletPointsHeadline: {
            headline: event.bulletPointsHeadline?.headline || '',
            headlineEN: event.bulletPointsHeadline?.headlineEN || '',
            bulletPoints: event.bulletPointsHeadline?.bulletPoints || [''],
            bulletPointsEN: event.bulletPointsHeadline?.bulletPointsEN || ['']
          },
          textHeadline: {
            headline: event.textHeadline?.headline || '',
            headlineEN: event.textHeadline?.headlineEN || '',
            textContent: event.textHeadline?.textContent || '',
            textContentEN: event.textHeadline?.textContentEN || ''
          }
        },
        image: null,
        program: null
      });
      setCurrentEvent(event);
    } else {
      setFormData({
        generalEvent: {
          bigHeadline: '',
          subHeadline: '',
          mainHeadline: '',
          subText: '',
          dateTime: '',
          location: '',
          programText: '',
          isActive: false,
          isMain: false,
          bulletPointsHeadline: {
            headline: '',
            headlineEN: '',
            bulletPoints: [''],
            bulletPointsEN: ['']
          },
          textHeadline: {
            headline: '',
            headlineEN: '',
            textContent: '',
            textContentEN: ''
          },
          bigHeadlineEN: '',
          subHeadlineEN: '',
          mainHeadlineEN: '',
          subTextEN: '',
          programTextEN: ''
        },
        image: null,
        program: null
      });
      setCurrentEvent(null);
    }
    setShowModal(true);
  };

  return (
    <Container>
      <Header>
        <Title>General Event Management</Title>
        <Button onClick={() => openModal()}>
          <Plus size={20} />
          Create New General Event
        </Button>
      </Header>
      <Table>
        <thead>
          <tr>
            <Th>Big Headline (DE/EN)</Th>
            <Th>Sub Headline (DE/EN)</Th>
            <Th>Main Headline (DE/EN)</Th>
            <Th>Date</Th>
            <Th>Location</Th>
            <Th>Active</Th>
            <Th>Main Event</Th>
            <Th>Actions</Th>
          </tr>
        </thead>
        <tbody>
          {generalEvents.map((event) => (
            <tr key={event.id}>
              <Td>{event.bigHeadline} / {event.bigHeadlineEN}</Td>
              <Td>{event.subHeadline} / {event.subHeadlineEN}</Td>
              <Td>{event.mainHeadline} / {event.mainHeadlineEN}</Td>
              <Td>{format(new Date(event.dateTime), 'PPP')}</Td>
              <Td>{event.location}</Td>
              <Td>{event.isActive ? 'Yes' : 'No'}</Td>
              <Td>{event.isMain ? 'Yes' : 'No'}</Td>
              <Td>
                <ActionButton onClick={() => openModal(event)}>
                  <Edit size={16} />
                </ActionButton>
                <ActionButton onClick={() => handleDelete(event.id)}>
                  <Trash2 size={16} />
                </ActionButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>

      {showModal && (
        <Modal>
          <ModalContent>
            <h2>{currentEvent ? 'Edit General Event' : 'Create New General Event'}</h2>
            <Form onSubmit={handleSubmit}>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <LanguageColumn>
                  <LanguageHeader>German Content</LanguageHeader>
                  <FormGroup>
                    <Label htmlFor="bigHeadline">Big Headline (DE)</Label>
                    <Input
                      type="text"
                      id="bigHeadline"
                      name="bigHeadline"
                      value={formData.generalEvent.bigHeadline}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="subHeadline">Sub Headline (DE)</Label>
                    <Input
                      type="text"
                      id="subHeadline"
                      name="subHeadline"
                      value={formData.generalEvent.subHeadline}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="mainHeadline">Main Headline (DE)</Label>
                    <Input
                      type="text"
                      id="mainHeadline"
                      name="mainHeadline"
                      value={formData.generalEvent.mainHeadline}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="subText">Sub Text (DE)</Label>
                    <TextArea
                      id="subText"
                      name="subText"
                      value={formData.generalEvent.subText}
                      onChange={handleInputChange}
                      rows={3}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="programText">Program Text (DE)</Label>
                    <TextArea
                      id="programText"
                      name="programText"
                      value={formData.generalEvent.programText}
                      onChange={handleInputChange}
                      rows={4}
                    />
                  </FormGroup>
                </LanguageColumn>
                <LanguageColumn>
                  <LanguageHeader>English Content</LanguageHeader>
                  <FormGroup>
                    <Label htmlFor="bigHeadlineEN">Big Headline (EN)</Label>
                    <Input
                      type="text"
                      id="bigHeadlineEN"
                      name="bigHeadlineEN"
                      value={formData.generalEvent.bigHeadlineEN}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="subHeadlineEN">Sub Headline (EN)</Label>
                    <Input
                      type="text"
                      id="subHeadlineEN"
                      name="subHeadlineEN"
                      value={formData.generalEvent.subHeadlineEN}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="mainHeadlineEN">Main Headline (EN)</Label>
                    <Input
                      type="text"
                      id="mainHeadlineEN"
                      name="mainHeadlineEN"
                      value={formData.generalEvent.mainHeadlineEN}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="subTextEN">Sub Text (EN)</Label>
                    <TextArea
                      id="subTextEN"
                      name="subTextEN"
                      value={formData.generalEvent.subTextEN}
                      onChange={handleInputChange}
                      rows={3}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="programTextEN">Program Text (EN)</Label>
                    <TextArea
                      id="programTextEN"
                      name="programTextEN"
                      value={formData.generalEvent.programTextEN}
                      onChange={handleInputChange}
                      rows={4}
                    />
                  </FormGroup>
                </LanguageColumn>
              </div>
              
              <FormGroup>
                <Label htmlFor="dateTime">Date and Time</Label>
                <Input
                  type="datetime-local"
                  id="dateTime"
                  name="dateTime"
                  value={formData.generalEvent.dateTime}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="location">Location</Label>
                <Input
                  type="text"
                  id="location"
                  name="location"
                  value={formData.generalEvent.location}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="program">Program File</Label>
                <Input
                  type="file"
                  id="program"
                  name="program"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx"
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <Checkbox
                    type="checkbox"
                    name="isActive"
                    checked={Boolean(formData.generalEvent.isActive)}
                    onChange={handleInputChange}
                  />
                  Active
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Checkbox
                    type="checkbox"
                    name="isMain"
                    checked={Boolean(formData.generalEvent.isMain)}
                    onChange={handleInputChange}
                  />
                  Main Event
                </Label>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="image">Main Image</Label>
                <Input
                  type="file"
                  id="image"
                  name="image"
                  onChange={handleFileChange}
                  accept="image/*"
                />
              </FormGroup>
              
              <div style={{ display: 'flex', gap: '1rem' }}>
              <LanguageColumn>
                  <LanguageHeader>Bullet Points (DE)</LanguageHeader>
                  <FormGroup>
                    <Label htmlFor="bulletPointsHeadline">Bullet Points Headline (DE)</Label>
                    <Input
                      type="text"
                      id="bulletPointsHeadline"
                      name="headline"
                      value={formData.generalEvent.bulletPointsHeadline.headline}
                      onChange={(e) => handleNestedInputChange(e, 'bulletPointsHeadline')}
                    />
                  </FormGroup>
                  {formData.generalEvent.bulletPointsHeadline.bulletPoints.map((point, index) => (
                    <BulletPointContainer key={index}>
                      <BulletPointInput
                        type="text"
                        value={point}
                        onChange={(e) => handleBulletPointChange(index, e.target.value, 'bulletPoints')}
                      />
                      <RemoveButton type="button" onClick={() => removeBulletPoint(index, 'bulletPoints')}>
                        <X size={16} />
                      </RemoveButton>
                    </BulletPointContainer>
                  ))}
                  <Button type="button" onClick={() => addBulletPoint('bulletPoints')}>
                    Add Bullet Point (DE)
                  </Button>
                </LanguageColumn>
                <LanguageColumn>
                  <LanguageHeader>Bullet Points (EN)</LanguageHeader>
                  <FormGroup>
                    <Label htmlFor="bulletPointsHeadlineEN">Bullet Points Headline (EN)</Label>
                    <Input
                      type="text"
                      id="bulletPointsHeadlineEN"
                      name="headlineEN"
                      value={formData.generalEvent.bulletPointsHeadline.headlineEN}
                      onChange={(e) => handleNestedInputChange(e, 'bulletPointsHeadline')}
                    />
                  </FormGroup>
                  {formData.generalEvent.bulletPointsHeadline.bulletPointsEN.map((point, index) => (
                    <BulletPointContainer key={index}>
                      <BulletPointInput
                        type="text"
                        value={point}
                        onChange={(e) => handleBulletPointChange(index, e.target.value, 'bulletPointsEN')}
                      />
                      <RemoveButton type="button" onClick={() => removeBulletPoint(index, 'bulletPointsEN')}>
                        <X size={16} />
                      </RemoveButton>
                    </BulletPointContainer>
                  ))}
                  <Button type="button" onClick={() => addBulletPoint('bulletPointsEN')}>
                    Add Bullet Point (EN)
                  </Button>
                </LanguageColumn>
              </div>
              
              <div style={{ display: 'flex', gap: '1rem' }}>
                <LanguageColumn>
                  <LanguageHeader>Text Content (DE)</LanguageHeader>
                  <FormGroup>
                    <Label htmlFor="textHeadline">Text Headline (DE)</Label>
                    <Input
                      type="text"
                      id="textHeadline"
                      name="headline"
                      value={formData.generalEvent.textHeadline.headline}
                      onChange={(e) => handleNestedInputChange(e, 'textHeadline')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="textContent">Text Content (DE)</Label>
                    <TextArea
                      id="textContent"
                      name="textContent"
                      value={formData.generalEvent.textHeadline.textContent}
                      onChange={(e) => handleNestedInputChange(e, 'textHeadline')}
                      rows={4}
                    />
                  </FormGroup>
                </LanguageColumn>
                <LanguageColumn>
                  <LanguageHeader>Text Content (EN)</LanguageHeader>
                  <FormGroup>
                    <Label htmlFor="textHeadlineEN">Text Headline (EN)</Label>
                    <Input
                      type="text"
                      id="textHeadlineEN"
                      name="headlineEN"
                      value={formData.generalEvent.textHeadline.headlineEN}
                      onChange={(e) => handleNestedInputChange(e, 'textHeadline')}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="textContentEN">Text Content (EN)</Label>
                    <TextArea
                      id="textContentEN"
                      name="textContentEN"
                      value={formData.generalEvent.textHeadline.textContentEN}
                      onChange={(e) => handleNestedInputChange(e, 'textHeadline')}
                      rows={4}
                    />
                  </FormGroup>
                </LanguageColumn>
              </div>
              <Button type="submit">Save</Button>
              <Button type="button" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
            </Form>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

export default GeneralEventManagement;