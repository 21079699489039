import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import bottomImage from '../assets/images/bottom.png';
import logoBig from '../assets/images/logo512.png';
import { Link } from 'react-router-dom';

const FooterWrapper = styled.footer`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TitlePart = styled.h2`
  font-size: 8rem;
  color: white;
  margin: 0;
  width: 50%;
  
  &.left {
    text-align: right;
    padding-right: 10px;
  }
  
  &.right {
    text-align: left;
    padding-left: 10px;
  }
  
  span {
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const HeartIcon = styled.div`
  width: 512px;
  height: 512px;
  background-image: url(${logoBig});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 1rem;
  margin-left: -20%; // Slightly displace to the left
  margin-bottom: 80px;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
    margin-left: 0;
  }

`;

const Email = styled.a`
  color: #48ABDB;
  text-decoration: none;
  font-size: 3rem;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ContactButton = styled.button`
  background-color: transparent;
  border: 3px solid #4a90e2;
  color: #48ABDB;
  padding: 0.5rem 1rem;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
    font-size: 1.2rem;
  }
`;

const CompanyInfo = styled.p`
  font-size: 1.4rem;
  color: white;
  margin-top: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const WaveBackground = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  height: 311px;
  background-image: url(${bottomImage});
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    height: 150px;
  }
`;

const WhiteText = styled.div`
  color: white;
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const Footer = () => {
  const intl = useIntl();

  const handleMailClick = () => {
    window.location.href = "mailto:crew@heartport.org";
  };

  const getPrivacyPolicyLink = () => {
    const lang = intl.locale === 'de' ? 'de' : 'en';
    return `${process.env.REACT_APP_API_URL || ''}/uploads/privacy_policy_${lang}.pdf`;
  };
  const getTandCLink = () => {
    return `${process.env.REACT_APP_API_URL || ''}/uploads/agb.pdf`;
  };

  return (
    <FooterWrapper>
      <TitleWrapper>
        <TitleRow>
          <TitlePart className="left">GET IN</TitlePart>
          <TitlePart className="right"></TitlePart>
        </TitleRow>
        <TitleRow>
          <TitlePart className="left"></TitlePart>
          <TitlePart className="right"><span>TOUCH</span></TitlePart>
        </TitleRow>
      </TitleWrapper>
      <HeartIcon />
      <Email href="mailto:crew@heartport.org">crew@heartport.org</Email>
      <WhiteText>
        <FormattedMessage id="footer.contactRequest" defaultMessage="Contact us" />
      </WhiteText>
      <ContactButton onClick={handleMailClick}>MAIL</ContactButton>
      <LinksContainer>
        <StyledLink to={getPrivacyPolicyLink()} target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="footer.privacyPolicy" defaultMessage="Privacy Policy" />
        </StyledLink>
        <StyledLink to={getTandCLink()} target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="footer.agb" defaultMessage="Terms and Conditions" />
        </StyledLink>
        <StyledLink to="/impressum">
          <FormattedMessage id="footer.impressum" defaultMessage="Imprint" />
        </StyledLink>
      </LinksContainer>
      <WaveBackground />
    </FooterWrapper>
  );
};

export default Footer;
