import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const ConferenceContext = createContext();

export const useConference = () => useContext(ConferenceContext);

export const ConferenceProvider = ({ children }) => {
  const [conference, setConference] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchConference = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        if (!apiUrl) {
          throw new Error('API URL is not defined');
        }

        const response = await axios.get(`${apiUrl}/api/cms/event/public/get-main`);
        
        if (response.data) {
          console.log("Found event context: ", response.data)
          setConference(response.data);
        } else {
          throw new Error('No conference data received');
        }
      } catch (error) {
        console.error('Error fetching conference data:', error);
        setError(error.message || 'An error occurred while fetching conference data');
      } finally {
        setLoading(false);
      }
    };

    fetchConference();
  }, []);

  const value = {
    conference: conference || {},
    loading,
    error,
  };

  return (
    <ConferenceContext.Provider value={value}>
      {children}
    </ConferenceContext.Provider>
  );
};