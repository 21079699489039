import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import api from '../../utils/authUtils';

const ProtectedAdminRoute = ({ children }) => {
  const [authStatus, setAuthStatus] = useState('checking');
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // Make a request to a protected endpoint to check if the user is authenticated
        await api.get('/api/protected');
        setAuthStatus('authenticated');
      } catch (error) {
        if (error.message === 'Session expired. Please login again.') {
          navigate('/admin/login?session_expired=true', { replace: true });
        } else {
          setAuthStatus('unauthenticated');
        }
      }
    };

    checkAuth();
  }, [navigate]);

  if (authStatus === 'checking') {
    // Still checking authentication status
    return <div>Loading...</div>;
  }

  if (authStatus === 'unauthenticated') {
    // User is not authenticated, redirect to login
    return <Navigate to="/admin/login" replace />;
  }

  // User is authenticated, render the protected route
  return children;
};

export default ProtectedAdminRoute;
